import React from "react";
import PropTypes from "prop-types";
import NavigationHeader from "components/NavigationHeader";
import { Badge, Button, Tab, Tabs } from "@unite-us/app-components";
import { stateBgColor, textColorForStyleProp } from "utils/stateBgColor";
import { capitalize } from "lodash";
import { useNavigate, useLocation } from "react-router";

const PlanHeader = ({ plan, crumbs, basePath, previousPath }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  return (
    <div
      className="flex flex-col items-start justify-center w-full border-b border-medium-border-blue"
      data-testid="plan-header-container"
    >
      <NavigationHeader
        crumbsData={[...crumbs, { label: plan?.name }]}
        backButtonRedirectTo={previousPath}
        dataTestId="plan-header-breadcrumbs"
      />

      <div className="px-20 w-full mt-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <h1 className="text-2xl">{plan?.name}</h1>
            <Badge
              className={`!leading-4 !text-sm px-4 mx-2 ${stateBgColor(plan?.state)}`}
              text={capitalize(plan?.state)}
              color=""
              style={textColorForStyleProp(plan?.state)}
            />
          </div>
          <Button
            id="edit-plan-button"
            icon={{ name: "Edit", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Edit Plan"
            data-testid="edit-plan-button"
            onClick={() => navigate(`${basePath}/edit`)}
          />
        </div>
        <Tabs
          id="plan-tabs"
          className="mt-6"
          onChange={(value) => navigate(`${basePath}/${value}`)}
          value={lastSegment}
        >
          <Tab
            id="plan-overview-tab"
            label="Overview"
            value="overview"
            dataTestId="overview-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
          <Tab
            id="plan-attachments-tab"
            label="Enrollments Attachments"
            value="attachments"
            dataTestId="enrollments-attachments-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
        </Tabs>
      </div>
    </div>
  );
};

PlanHeader.propTypes = {
  plan: PropTypes.object.isRequired,
  crumbs: PropTypes.array.isRequired,
  basePath: PropTypes.string.isRequired,
  previousPath: PropTypes.string.isRequired,
};

export default PlanHeader;
