import React from "react";
import PlanView from "components/pages/Plan/PlanView";
import { useParams } from "react-router";
import { useFindFeeSchedule } from "api/core/feeScheduleHooks";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Spinner from "components/Spinner/Spinner";

const FeeSchedulePlan = () => {
  const { feeScheduleId, planId } = useParams();

  const {
    data: feeSchedule,
    isFetching: isFetchingFeeSchedule,
    isError: isErrorFeeSchedule,
  } = useFindFeeSchedule(feeScheduleId);
  const basePath = `/fee-schedules/${feeScheduleId}/plans/${planId}`;
  const previousPath = `/fee-schedules/${feeScheduleId}/plans`;
  const crumbs = [
    { href: "/fee-schedules", label: "Fee Schedules" },
    {
      href: `/fee-schedules/${feeScheduleId}`,
      label: feeSchedule?.name,
    },
    { href: `/fee-schedules/${feeScheduleId}/plans`, label: "Plans" },
  ];
  const shouldRenderPlanView = !isFetchingFeeSchedule && !isErrorFeeSchedule;

  return (
    <>
      {isFetchingFeeSchedule && <Spinner center />}
      {isErrorFeeSchedule && (
        <ErrorMessage
          navigateText="Go Back to Fee Schedules Page"
          navigateUrl="/fee-schedules"
          errorText="Error Fetching Fee Schedules"
          dataTestId="fee-schedule-fetch-error-message"
        />
      )}
      {shouldRenderPlanView && (
        <PlanView
          crumbs={crumbs}
          basePath={basePath}
          previousPath={previousPath}
        />
      )}
    </>
  );
};

export default FeeSchedulePlan;
