import { useCreateRecord, useUpdateRecord, useFind } from "api/apiHooks.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { useDefaultMutationConfig } from "api/apiUtils.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useDefaultPagingQueryConfig } from "api/apiUtils.js";

export const useInvoiceRejectionReasons = ({
  filters = {},
  include,
  pageSize,
  pageNumber,
  sort,
}) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind("invoice_rejection_reason", { ...filters }, jsonApiAdapter, {
    queryConfig: { ...defaultPagingQueryConfig },
    page: { number: pageNumber || 1, size: pageSize || 15 },
    sort: sort || "",
    include: include || "",
  });
};

export const useCreateInvoiceRejectionReason = (mutationConfig) => {
  const { authToken } = useAuthContext();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useCreateRecord("invoice_rejection_reason", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useUpdateInvoiceRejectionReason = (mutationConfig) => {
  const { authToken } = useAuthContext();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useUpdateRecord("invoice_rejection_reason", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};
