import React from "react";
import PlanView from "components/pages/Plan/PlanView";
import { useParams } from "react-router";
import { useFindPayer } from "api/core/payerHooks";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Spinner from "components/Spinner/Spinner";

const PayerPlan = () => {
  const { payerId, planId } = useParams();

  const {
    data: payer,
    isFetching: isFetchingPayer,
    isError: isErrorPayer,
  } = useFindPayer(payerId);
  const basePath = `/payers/${payerId}/plans/${planId}`;
  const previousPath = `/payers/${payerId}`;
  const crumbs = [
    { href: "/payers", label: "Payers" },
    {
      href: `/payers/${payerId}`,
      label: payer?.name,
    },
    { label: "Plans" },
  ];
  const shouldRenderPlanView = !isFetchingPayer && !isErrorPayer;

  return (
    <>
      {isFetchingPayer && <Spinner center />}
      {isErrorPayer && (
        <ErrorMessage
          navigateText="Go Back to Payers Page"
          navigateUrl="/payers"
          errorText="Error Fetching Payer"
          dataTestId="payer-fetch-error-message"
        />
      )}
      {shouldRenderPlanView && (
        <PlanView
          crumbs={crumbs}
          basePath={basePath}
          previousPath={previousPath}
        />
      )}
    </>
  );
};

export default PayerPlan;
