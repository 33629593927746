import {
  useFind,
  useFindRecord,
  useCreateRecord,
  useUpdateRecord,
  useInvalidateQueries,
  useSetRelationship,
} from "api/apiHooks.js";
import {
  useDefaultPagingQueryConfig,
  useDefaultQueryConfig,
  useDefaultMutationConfig,
} from "api/apiUtils.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { useToast } from "@unite-us/app-components";

export const usePlans = ({
  filters = {},
  include,
  pageSize,
  pageNumber,
  sort,
}) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind("plan", filters, jsonApiAdapter, {
    queryConfig: { enabled: filters.id !== "", ...defaultPagingQueryConfig },
    page: { number: pageNumber || 1, size: pageSize || 25 },
    sort: sort || "",
    include: include || "",
  });
};

export const useFindPlan = (planId) => {
  const { authToken } = useAuthContext();
  const defaultQueryConfig = useDefaultQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFindRecord("plan", planId, jsonApiAdapter, {
    queryConfig: defaultQueryConfig,
  });
};

export const useCreatePlan = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useCreateRecord("plan", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
      onSuccess: () => {
        invalidateQueries("plan");
        invalidateQueries("payer");
        addToast({
          message: "Plan created successfully",
          type: "success",
          dataTestId: "create-plan-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error creating plan: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "create-plan-error-toast",
        });
      },
    },
  });
};

export const useUpdatePlan = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useUpdateRecord("plan", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
      onSuccess: () => {
        invalidateQueries("plan");
        invalidateQueries("payer");
        addToast({
          message: "Plan updated successfully",
          type: "success",
          dataTestId: "update-plan-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error updating plan: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "edit-plan-error-toast",
        });
      },
    },
  });
};

export const useSetPlanFeeSchedule = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useSetRelationship("plan", "fee_schedule", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
      onSuccess: () => {
        invalidateQueries("plan");
        invalidateQueries("fee_schedule");
        addToast({
          message: "Plan added to Fee Schedule successfully",
          type: "success",
          dataTestId: "add-plan-to-fee-schedule-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error adding plan to fee schedule: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "add-plan-to-fee-schedule-error-toast",
        });
      },
    },
  });
};
