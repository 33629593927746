import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { useDefaultPagingQueryConfig } from "api/apiUtils.js";
import { useFind } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";

export const useUniteUsServices = ({
  filters = {},
  pageNumber = 1,
  pageSize = 300,
  queryConfig = {},
}) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();
  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  const { data, ...rest } = useFind(
    "service",
    { ...filters, taxonomy: "uniteus" },
    jsonApiAdapter,
    {
      queryConfig: { ...defaultPagingQueryConfig },
      page: { number: pageNumber, size: pageSize },
    },
  );
  const childServices = data?.data.filter((st) => st.parent);
  const flattenedServices = data?.data
    .sort((d) => d.name)
    .reduce((map, parent) => {
      if (!Object.prototype.hasOwnProperty.call(parent, "parent")) {
        map[parent.id] = {
          ...parent,
          children: childServices.filter((st) => st.parent.id === parent.id),
        };
      }
      return map;
    }, {});

  return { data: flattenedServices, ...rest };
};
