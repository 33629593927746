import React from "react";
import { useNavigate, useParams } from "react-router";
import {
  useFindFeeScheduleProgram,
  useUpdateFeeScheduleProgram,
} from "api/core/feeScheduleProgramHooks";
import { useUniteUsServices } from "api/core/serviceHooks";
import constructFeeScheduleProgramInitialFormValues from "../utils/constructFeeScheduleProgramInitialFormValues";
import Spinner from "components/Spinner/Spinner";
import constructFeeScheduleProgramFormPayload from "../utils/constructFeeScheduleProgramFormPayload";
import getServiceTypesWithParents from "../utils/getServiceTypesWithParents";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import FeeScheduleProgramForm from "components/Forms/FeeScheduleProgramForm";
import { useFindFeeSchedule } from "api/core/feeScheduleHooks";
import { useToast } from "@unite-us/app-components";

const EditFeeScheduleProgram = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { feeScheduleId, feeScheduleProgramId } = useParams();

  const { updateRecord: updateFeeScheduleProgram } =
    useUpdateFeeScheduleProgram({ onError: () => {} });

  const {
    data: parentServices,
    isFetching: isFetchingServices,
    isError: isErrorServices,
  } = useUniteUsServices({ filters: {} });

  const {
    data: feeScheduleProgram,
    isFetching: isFetchingProgram,
    isError: isErrorProgram,
  } = useFindFeeScheduleProgram(feeScheduleProgramId);
  const {
    data: feeSchedule,
    isFetching: isFetchingFeeSchedule,
    isError: isErrorFeeSchedule,
  } = useFindFeeSchedule(feeScheduleId);

  const isFetching =
    isFetchingProgram || isFetchingFeeSchedule || isFetchingServices;
  const isError = isErrorProgram || isErrorFeeSchedule || isErrorServices;

  const initialValues =
    constructFeeScheduleProgramInitialFormValues(feeScheduleProgram);

  const handleSubmit = async (feeScheduleProgram) => {
    const servicesWithParents = getServiceTypesWithParents(
      feeScheduleProgram.services,
      Object.values(parentServices),
    );

    const payload = constructFeeScheduleProgramFormPayload(
      feeScheduleProgram,
      feeScheduleId,
      servicesWithParents,
    );

    try {
      await updateFeeScheduleProgram(feeScheduleProgramId, payload);
      navigate(
        `/fee-schedules/${feeScheduleId}/fee-schedule-programs/${feeScheduleProgramId}`,
      );
    } catch (error) {
      console.error("Error updating fee schedule program: ", error);
      addToast({
        message: `Error updating fee schedule program: ${error?.response?.data?.errors[0]?.title || "Unknown error"}`,
        type: "error",
        dataTestId: "update-fee-schedule-program-error-toast",
      });
    }
  };

  return (
    <>
      {isFetching && (
        <Spinner
          className="h-screen"
          center
          dataTestId="edit-fee-schedule-program-spinner"
        />
      )}
      {isError && (
        <ErrorMessage
          navigateUrl={`/fee-schedules/${feeScheduleId}`}
          errorText="Error Fetching Fee Schedule Program"
          dataTestId="fee-schedule-program-edit-error"
          navigateText="Go Back to Fee Schedule Page"
        />
      )}
      {!isFetching && !isError && (
        <FeeScheduleProgramForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          feeSchedule={feeSchedule}
          feeScheduleProgramId={feeScheduleProgramId}
          services={parentServices}
        />
      )}
    </>
  );
};

export default EditFeeScheduleProgram;
