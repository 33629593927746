import React from "react";
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
} from "@unite-us/app-components";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import { useFindFeeScheduleScreening } from "api/core/feeScheduleScreeningHooks";
import moment from "moment";
import { useParams } from "react-router";

const FeeScheduleScreeningOverview = () => {
  const { feeScheduleId, feeScheduleScreeningId } = useParams();

  const {
    data: feeScheduleScreening,
    isFetching: isFetchingFeeScheduleScreening,
    isError: isErrorFeeScheduleScreening,
  } = useFindFeeScheduleScreening(feeScheduleScreeningId);

  return (
    <>
      {isFetchingFeeScheduleScreening && (
        <Spinner center dataTestId={"fee-schedule-screening-spinner"} />
      )}
      {isErrorFeeScheduleScreening && (
        <ErrorMessage
          dataTestId="fee-schedule-screening-overview-error"
          errorText="Error Fetching Fee Schedule Screening"
          navigateUrl={`/fee-schedules/${feeScheduleId}/fee-schedule-screenings`}
          navigateText="Go Back to Fee Schedule Screenings Page"
        />
      )}
      {!isErrorFeeScheduleScreening && !isFetchingFeeScheduleScreening && (
        <div data-testid="fee-schedule-screening-overview-base-card">
          <BaseCard>
            <BaseCardHeader
              title="Fee Schedule Screening Details"
              className="font-bold text-xl bg-gray-100 px-4"
            />
            <BaseCardBody withPadding className="p-8 bg-white">
              <div className="grid grid-cols-6">
                <div className="col-start-1 pl-0 pb-2 font-bold">Name:</div>
                <div>{feeScheduleScreening.name}</div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Start Date:
                </div>
                <div className="capitalize">
                  {moment
                    .utc(feeScheduleScreening.starts_at)
                    .format("MM/DD/YYYY")}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">End Date:</div>
                <div className="capitalize">
                  {moment
                    .utc(feeScheduleScreening.ends_at)
                    .format("MM/DD/YYYY")}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Unit Rate (Cents):
                </div>
                <div className="capitalize">
                  {feeScheduleScreening.unit_rate}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">State:</div>
                <div className="capitalize">{feeScheduleScreening.state}</div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Code Type:
                </div>
                <div className="capitalize">
                  {feeScheduleScreening.code_type}
                </div>
                <div className="col-start-1 pl-0 font-bold">Code:</div>
                <div className="capitalize">{feeScheduleScreening.code}</div>
              </div>
            </BaseCardBody>
          </BaseCard>
        </div>
      )}
    </>
  );
};

export default FeeScheduleScreeningOverview;
