import React from "react";
import propTypes from "prop-types";
import { useNavigate } from "react-router";

const ErrorMessage = ({ navigateUrl, dataTestId, errorText, navigateText }) => {
  const navigate = useNavigate();
  return (
    <div data-testid={dataTestId} className="text-center pt-11">
      <div className="text-center">{errorText}</div>
      <span
        className="text-action-blue cursor-pointer text-base"
        onClick={() => navigate(navigateUrl)}
      >
        {navigateText}
      </span>
    </div>
  );
};

ErrorMessage.propTypes = {
  navigateUrl: propTypes.string.isRequired,
  dataTestId: propTypes.string.isRequired,
  errorText: propTypes.string.isRequired,
  navigateText: propTypes.string.isRequired,
};

export default ErrorMessage;
