import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PlanForm from "components/Forms/PlanForm";
import { useUpdatePlan, useFindPlan } from "api/core/planHooks";
import { useNetworks } from "api/core/networkHooks";
import { useNavigate, useParams } from "react-router";
import { useToast } from "@unite-us/app-components";
import Spinner from "components/Spinner/Spinner";
import { constructPlanFormPayload } from "utils/payloadUtils/constructPlanFormPayload";
import { constructPlanInitialFormValues } from "utils/payloadUtils/constructPlanInitialFormValues";

const EditPlan = ({ returnUrl }) => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const { addToast } = useToast();
  const { updateRecord: updatePlan } = useUpdatePlan();
  const {
    data: plan,
    isFetching: isFetchingPlan,
    isError: isErrorFetchingPlan,
    error: errorPlan,
  } = useFindPlan(planId);
  const payerId = plan?.payer?.id;
  const networkIds =
    plan?.networks?.map((network) => network.id).join(",") || "";
  const {
    data: networksResponse,
    isFetching: isFetchingNetworks,
    isError: isErrorFetchingNetworks,
    error: errorNetwork,
  } = useNetworks({
    filters: { id: networkIds },
  });
  const { data: networksData } = networksResponse;

  const isFetching = isFetchingPlan || isFetchingNetworks;
  const isErrorFetching = isErrorFetchingPlan || isErrorFetchingNetworks;
  const errorMessage = errorPlan?.message || errorNetwork?.message;

  const initialValues = constructPlanInitialFormValues(plan, networksData);

  const handleSubmit = async (values) => {
    const payload = constructPlanFormPayload(values, payerId);
    try {
      await updatePlan(planId, payload);
      navigate(returnUrl);
    } catch (error) {
      console.error("Error updating plan: ", error);
    }
  };

  useEffect(() => {
    if (isErrorFetching) {
      addToast({
        message: `Error fetching plan data: ${errorMessage || "Unknown error"}`,
        type: "error",
        dataTestId: "loading-edit-plan-error-toast",
      });
      navigate(returnUrl);
    }
  }, [isErrorFetching, errorMessage, navigate, addToast, returnUrl]);

  return (
    <>
      {isFetching ? (
        <Spinner className="h-screen" center dataTestId="edit-plan-spinner" />
      ) : (
        <PlanForm
          onSubmit={handleSubmit}
          payerId={payerId}
          planId={planId}
          initialValues={initialValues}
          returnUrlForEdit={returnUrl}
        />
      )}
    </>
  );
};

EditPlan.propTypes = {
  returnUrl: PropTypes.string.isRequired,
};

export default EditPlan;
