import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "@unite-us/app-components";
import OrganizationTable from "./OrganizationTable";
import UsersFromOrgTable from "./UsersFromOrgTable";
import { useSetCareManagers } from "api/core/feeScheduleHooks";

const AddCareManagersModal = ({
  modalRef,
  closeModal,
  feeScheduleId,
  careManagers,
}) => {
  const [isSelectingOrg, setIsSelectingOrg] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const { setRelationship: setCareManagers } = useSetCareManagers();

  const closeHandler = () => {
    setIsSelectingOrg(true);
    setSelectedOrg({});
    setSelectedUsers(new Set());
    closeModal();
  };

  const addUsersHandler = async () => {
    try {
      await setCareManagers(feeScheduleId, Array.from(selectedUsers));
      closeHandler();
    } catch (error) {
      console.error("Error setting care managers", error);
    }
  };

  const careManagersIds = careManagers.map((careManager) => careManager.id);

  const Footer = () => (
    <div className="flex justify-end space-x-6">
      <Button
        id="add-care-managers-close-btn"
        className="text-sm text-brand-blue rounded border solid border-med-pale-blue bg-white normal-case"
        label="Cancel"
        data-testid="add-care-managers-close-btn"
        onClick={closeHandler}
      />
      {isSelectingOrg ? (
        <Button
          id="add-care-managers-next-btn"
          className="text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case"
          label="Next"
          data-testid="add-care-managers-next-btn"
          disabled={!selectedOrg.id}
          onClick={() => setIsSelectingOrg(false)}
        />
      ) : (
        <Button
          id="add-care-managers-add-btn"
          className="text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case"
          label="Add Users"
          data-testid="add-care-managers-add-btn"
          disabled={selectedUsers.size === 0}
          onClick={addUsersHandler}
        />
      )}
    </div>
  );

  return (
    <Modal
      ref={modalRef}
      modalContentStyles="bg-medium-fill-grey overflow-y-hidden grid"
      title={`Add New Care Managers`}
      data-testid="add-care-managers-modal"
      spinnerShow={false}
      onRequestClose={closeHandler}
      showBorderRadius={true}
      disableScroll={true}
      modal
      actions={<Footer />}
      size="jumbo"
    >
      {isSelectingOrg ? (
        <OrganizationTable
          selectedOrg={selectedOrg}
          setSelectedOrg={setSelectedOrg}
        />
      ) : (
        <UsersFromOrgTable
          org={selectedOrg}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          careManagersIds={careManagersIds}
        />
      )}
    </Modal>
  );
};

AddCareManagersModal.propTypes = {
  modalRef: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  feeScheduleId: PropTypes.string.isRequired,
  careManagers: PropTypes.array.isRequired,
};

export default AddCareManagersModal;
