import React from "react";
import { useOutletContext } from "react-router";
import PlanAttachments from "components/pages/Plan/PlanAttachments";
import PlanEnrollments from "components/pages/Plan/PlanEnrollments";

const FeeSchedulePlanEnrollmentsAttachments = () => {
  const plan = useOutletContext();

  return (
    <div data-testid="fee-schedule-plan-attachments-container">
      {plan.plan_type === "social" && <PlanEnrollments plan={plan} />}
      <PlanAttachments plan={plan} />
    </div>
  );
};

export default FeeSchedulePlanEnrollmentsAttachments;
