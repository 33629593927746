import { useState } from "react";
import SearchBar from "components/SearchBar/SearchBar";
import PaginatedTable from "components/Tables/PaginatedTable";
import { Button } from "@unite-us/app-components";
import { usePlans, useSetPlanFeeSchedule } from "api/core/planHooks";
import { Icon } from "@unite-us/app-components";

const tableHeaders = [
  { label: "Plan Name" },
  { label: "External ID" },
  { label: "Plan UUID" },
  { label: "Included" },
];
export const AddPlanToFeeSchedule = ({
  feeSchedule,
  onCancelAddNewPlan,
  onSubmitAddNewPlan,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchPlanText, setSearchPlanText] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const existingFeeSchedulePlanIds = new Set(
    feeSchedule?.plans.map((plan) => plan?.id),
  );
  const {
    data: plansResponse,
    isFetching,
    isError,
  } = usePlans({
    filters: { name: searchPlanText, plan_type: "social" },
    pageSize,
    pageNumber,
  });
  const { data: plans, paging } = plansResponse || {};
  let tableBody = plans?.map((plan) => {
    const tableRow = {
      rowId: plan.id,
      rowData: [
        { data: plan.name },
        { data: plan.external_id },
        { data: plan.id },
        {
          data:
            selectedPlan === plan.id ||
            existingFeeSchedulePlanIds.has(plan?.id) ? (
              <Icon icon="CheckCircle" size={20} />
            ) : undefined,
        },
      ],
      rowClassName: `${
        selectedPlan === plan.id
          ? "hover:bg-dark-fill-blue bg-dark-fill-blue"
          : ""
      }${existingFeeSchedulePlanIds.has(plan?.id) ? "disabled" : ""}`,
    };
    return tableRow;
  });

  const { setRelationship: setPlanFeeSchedule } = useSetPlanFeeSchedule();

  return (
    <>
      <SearchBar
        onSubmit={(searchText) => {
          setPageNumber(1);
          setSearchPlanText(searchText.search);
        }}
        dataTestId="add-plan-to-feeschedule-search-bar"
        placeholder="Enter The Plan Name"
        delay={2000}
        inputClassName=""
        inputStyle={{ height: "40px" }}
        showIcon={true}
      />
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        emptyTableMessage="No plans found"
        pageSize={pageSize}
        pageNumber={pageNumber}
        paging={paging}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        dataTestId="add-plan-to-feeschedule-table"
        isError={isError}
        onRowClick={(e) => {
          if (!existingFeeSchedulePlanIds.has(e)) {
            setSelectedPlan(e);
          }
        }}
        showSpinnerOnEmptyFetch
      />
      <div className="flex space-x-2 justify-end pt-4">
        <Button
          id="cancel-add-plan-to-feeschedule-button"
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
          }
          label="Cancel"
          data-testid="cancel-add-plan-to-feeschedule-button"
          onClick={onCancelAddNewPlan}
        />
        <Button
          id="confirm-add-plan-to-feeschedule-button"
          className={
            "py-0 px-4 text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case h-9"
          }
          label="Confirm"
          data-testid="confirm-add-plan-to-feeschedule-button"
          onClick={async () => {
            try {
              if (selectedPlan) {
                await setPlanFeeSchedule(selectedPlan, [feeSchedule.id]);
                setSelectedPlan(null);
                onSubmitAddNewPlan();
              }
            } catch (error) {
              console.error("Error adding plan to fee schedule: ", error);
            }
          }}
        />
      </div>
    </>
  );
};
