import React from "react";
import { useNavigate, useParams } from "react-router";
import {
  useUpdateFeeSchedule,
  useFindFeeSchedule,
} from "api/core/feeScheduleHooks";
import constructFeeScheduleFormPayload from "../utils/constructFeeScheduleFormPayload";
import constructFeeScheduleInitialFormValues from "./utils/constructFeeScheduleInitialFormValues";
import FeeScheduleForm from "components/Forms/FeeScheduleForm";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const EditFeeSchedule = () => {
  const navigate = useNavigate();
  const { feeScheduleId } = useParams();
  const { updateRecord: updateFeeSchedule } = useUpdateFeeSchedule();
  const {
    data: feeSchedule,
    isFetching,
    isError,
  } = useFindFeeSchedule(feeScheduleId);
  const initialValues = constructFeeScheduleInitialFormValues(feeSchedule);

  const handleSubmit = async (feeSchedule) => {
    const payload = constructFeeScheduleFormPayload(feeSchedule);
    try {
      await updateFeeSchedule(feeScheduleId, payload);
      navigate(`/fee-schedules/${feeScheduleId}`);
    } catch (error) {
      console.error("Error updating fee schedule: ", error);
    }
  };

  return (
    <>
      {isFetching && (
        <Spinner
          className="h-screen"
          center
          dataTestId="edit-fee-schedule-spinner"
        />
      )}
      {isError && (
        <ErrorMessage
          dataTestId="fee-schedule-edit-error"
          errorText="Error Fetching Fee Schedule"
          navigateUrl="/fee-schedules"
          navigateText="Go Back to Fee Schedules Page"
        />
      )}
      {!isFetching && !isError && (
        <FeeScheduleForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          feeScheduleId={feeScheduleId}
        />
      )}
    </>
  );
};

export default EditFeeSchedule;
