import React from "react";
import PropTypes from "prop-types";
import NavigationHeader from "components/NavigationHeader";
import { Button, Badge, Tab, Tabs } from "@unite-us/app-components";
import { stateBgColor, textColorForStyleProp } from "utils/stateBgColor";
import { capitalize } from "lodash";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

const FeeScheduleHeader = ({ feeSchedule, crumbs }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  return (
    <div
      className="flex flex-col items-start justify-center w-full border-b border-medium-border-blue"
      data-testid="fee-schedule-header-container"
    >
      <NavigationHeader
        crumbsData={crumbs}
        backButtonRedirectTo={`/fee-schedules`}
        dataTestId="fee-schedule-header-breadcrumbs"
      />

      <div className="px-20 w-full mt-4">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center">
              <h1 className="text-2xl">{feeSchedule.name}</h1>
              <Badge
                className={`!leading-4 !text-sm px-4 mx-2 ${stateBgColor(feeSchedule.state)}`}
                text={capitalize(feeSchedule.state)}
                color=""
                style={textColorForStyleProp(feeSchedule.state)}
              />
            </div>
            <div className="flex flex-row space-x-6">
              <Button
                id="archive-fee-schedule-button"
                icon={{ name: "Archive", color: "text-blue", position: "left" }}
                className={
                  "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
                }
                label="Archive"
                data-testid="archive-fee-schedule-button"
                disabled
              />
              <Button
                id="edit-fee-schedule-button"
                icon={{ name: "Edit", color: "text-blue", position: "left" }}
                className={
                  "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
                }
                label="Edit"
                data-testid="edit-fee-schedule-button"
                onClick={() =>
                  navigate(`/fee-schedules/${feeSchedule.id}/edit`)
                }
              />
            </div>
          </div>

          <div className="flex items-center gap-2 text-gray-700">
            <div className="flex items-center">
              <span className="text-base font-semibold uppercase">
                Start Date:
              </span>
              <span className="ml-2 text-base">
                {moment.utc(feeSchedule.starts_at).format("MM/DD/YYYY")}
              </span>
            </div>
            <div className="mx-4 text-xl font-light">|</div>
            <div className="flex items-center">
              <span className="text-base font-semibold uppercase">
                End Date:
              </span>
              <span className="ml-2 text-base">
                {moment.utc(feeSchedule.ends_at).format("MM/DD/YYYY")}
              </span>
            </div>
          </div>
        </div>

        <Tabs
          id="fee-schedule-tabs"
          className="mt-6"
          onChange={(value) =>
            navigate(`/fee-schedules/${feeSchedule.id}/${value}`)
          }
          value={lastSegment}
        >
          <Tab
            id="fee-schedule-programs-tab"
            label="Fee Schedule Programs"
            value="fee-schedule-programs"
            dataTestId="fee-schedule-programs-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
          <Tab
            id="fee-schedule-plans-tab"
            label="Plans"
            value="plans"
            dataTestId="fee-schedule-plans-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
          <Tab
            id="fee-schedule-screenings-tab"
            label="Screenings"
            value="fee-schedule-screenings"
            dataTestId="fee-schedule-screenings-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
          <Tab
            id="invoice-rejection-reasons-tab"
            label="Invoice Rejection Reasons"
            value="invoice-rejection-reasons"
            dataTestId="invoice-rejection-reasons-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
          <Tab
            id="authorization-rejection-reasons-tab"
            label="Authorization Rejection Reasons"
            value="authorization-rejection-reasons"
            dataTestId="authorization-rejection-reasons-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
          <Tab
            id="care-managers-tab"
            label="Care Managers"
            value="care-managers"
            dataTestId="care-managers-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
        </Tabs>
      </div>
    </div>
  );
};

FeeScheduleHeader.propTypes = {
  feeSchedule: PropTypes.object.isRequired,
  crumbs: PropTypes.array.isRequired,
};

export default FeeScheduleHeader;
