import React from "react";
import { useParams } from "react-router";
import { useFindFeeSchedule } from "api/core/feeScheduleHooks";
import Spinner from "components/Spinner/Spinner";
import FeeScheduleHeader from "./components/FeeScheduleHeader";
import { Outlet } from "react-router";

const FeeSchedule = () => {
  const { feeScheduleId } = useParams();
  const {
    data: feeSchedule,
    isFetching,
    isError,
  } = useFindFeeSchedule(feeScheduleId);
  const shouldRenderTabComponent = !isFetching && !isError;
  const crumbs = [
    { label: "Fee Schedules", href: "/fee-schedules" },
    { label: feeSchedule.name },
  ];

  return (
    <div className="h-full flex flex-grow-0 flex-col">
      <FeeScheduleHeader feeSchedule={feeSchedule} crumbs={crumbs} />
      <div className="pt-8 pb-10 px-20 h-full flex flex-grow-0 flex-col overflow-y-auto">
        {isFetching && <Spinner center />}
        {isError && <div>There was an error fetching fee schedule</div>}
        {shouldRenderTabComponent && <Outlet />}
      </div>
    </div>
  );
};

export default FeeSchedule;
