import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "@unite-us/app-components";
import RejectionReasonForm from "./RejectionReasonForm";

const EditRejectionReasons = ({
  reasons,
  modalRef,
  confirmationHandler,
  closeModal,
}) => {
  const [newReasons, setNewReasons] = useState(reasons || []);

  useEffect(() => {
    setNewReasons(reasons);
  }, [reasons]);

  const cancelHandler = () => {
    setNewReasons([...reasons]);
    closeModal();
  };

  const setNewReason = (editedReason, index) => {
    const editedReasons = [...newReasons];
    editedReasons[index] = editedReason;
    setNewReasons(editedReasons);
  };

  const addReason = () => {
    const updatedReasons = [...newReasons];
    updatedReasons[updatedReasons.length] = {};
    setNewReasons(updatedReasons);
  };

  const removeNewReason = (index) => {
    const updatedReasons = [...newReasons];
    updatedReasons.splice(index, 1);
    setNewReasons(updatedReasons);
  };

  const Footer = () => (
    <div className="flex justify-end space-x-6">
      <Button
        id="cancel-rejection-reasons-button"
        className="text-sm text-brand-blue rounded border solid border-med-pale-blue bg-white normal-case"
        label="Cancel"
        data-testid="cancel-rejection-reasons-button"
        onClick={cancelHandler}
      />
      <Button
        id="save-rejection-reasons-button"
        className="text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case"
        label="Save"
        data-testid="save-rejection-reasons-button"
        onClick={() => confirmationHandler(newReasons)}
      />
    </div>
  );

  return (
    <Modal
      ref={modalRef}
      modalContentStyles="flex-auto overflow-y-auto bg-medium-fill-blue"
      title="Add/Edit Rejection Reasons"
      data-testid="add-edit-rejection-reasons-modal"
      spinnerShow={false}
      onRequestClose={cancelHandler}
      modal
      actions={<Footer />}
      size="small"
      focusTrap
      customSize={{
        maxHeight: "100%",
        height: "100%",
        left: "auto",
        right: "0",
        transform: "translateY(-50%)",
        width: "450px",
      }}
    >
      {newReasons?.map((reason, index) => (
        <RejectionReasonForm
          key={`rejection-reason-form-${index}`}
          rejectionReason={reason}
          setRejectionReason={setNewReason}
          index={index}
          removeReason={removeNewReason}
        />
      ))}
      <Button
        id="add-rejection-reason-button"
        icon={{ name: "Add", color: "text-blue", position: "left" }}
        className="py-0 px-4 text-sm !text-text-blue rounded border solid border-light-border-blue bg-white normal-case h-9 w-fit"
        label="Add Rejection Reason"
        onClick={() => addReason()}
      />
    </Modal>
  );
};

EditRejectionReasons.propTypes = {
  reasons: PropTypes.array.isRequired,
  modalRef: PropTypes.object.isRequired,
  confirmationHandler: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default EditRejectionReasons;
