import React from "react";
import PropTypes from "prop-types";
import { DateField, InputField } from "@unite-us/ui";
import { RadioField, Button } from "@unite-us/app-components";
import { Form, Field } from "react-final-form";
import { isRequired } from "utils/validations";
import { useNavigate } from "react-router";
import NavigationHeader from "components/NavigationHeader";
import { validateFSStartDate, validateFSEndDate } from "utils/validations";
import getFeeScheduleEndDateMinDate from "pages/fee-schedules/utils/getFeeScheduleEndDateMinDate";
import moment from "moment";
import { feeScheduleFormConstants } from "./utils/constants";

const FeeScheduleForm = ({ onSubmit, initialValues, feeScheduleId }) => {
  const navigate = useNavigate();
  const isEditing = !!feeScheduleId;

  const backRedirection = isEditing
    ? `/fee-schedules/${feeScheduleId}`
    : `/fee-schedules`;

  const { STATE_OPTIONS, SCC_VALIDATION_OPTIONS } = feeScheduleFormConstants;

  const namespace = "fee-schedule-form";

  const onCancel = () => {
    navigate(backRedirection);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full pb-4">
      <NavigationHeader
        backButtonRedirectTo={backRedirection}
        dataTestId={`${namespace}-navigation-header`}
      />
      <div className="flex flex-row justify-start w-2/5 min-w-80 py-4">
        <h1 className="text-xl font-semibold">
          {isEditing ? "Edit Fee Schedule" : "Add New Fee Schedule"}
        </h1>
      </div>
      <div className="border-2 rounded bg-medium-fill-grey p-8 w-2/5 min-w-80">
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="name"
                validate={(val) => isRequired(val, "Name is required")}
              >
                {(props) => (
                  <InputField
                    id={`${namespace}__name`}
                    label="Name"
                    dataTestId={`${namespace}-name`}
                    className="w-full !pb-0"
                    required
                    {...props}
                  />
                )}
              </Field>
              <div className="flex gap-x-6">
                <Field
                  name="starts_at"
                  validate={(value) =>
                    validateFSStartDate(value, values.ends_at)
                  }
                >
                  {(props) => (
                    <DateField
                      className="w-1/2"
                      id={`${namespace}__starts-at`}
                      dataTestId={`${namespace}-starts-at`}
                      customErrorMessage="Start date must be before end date"
                      label="Start Date"
                      maxDate={
                        values.ends_at || initialValues.ends_at
                          ? moment(
                              values.ends_at ?? initialValues.ends_at,
                              "MM/DD/YYYY",
                            )
                              .subtract(1, "day")
                              .format("MM/DD/YYYY")
                          : null
                      }
                      required
                      valueFormat="MM/DD/YYYY"
                      {...props}
                    />
                  )}
                </Field>
                <Field
                  name="ends_at"
                  validate={(value) =>
                    validateFSEndDate(value, values.starts_at)
                  }
                >
                  {(props) => (
                    <DateField
                      className="w-1/2"
                      id={`${namespace}__ends-at-field`}
                      dataTestId={`${namespace}-ends-at`}
                      label="End Date"
                      minDate={getFeeScheduleEndDateMinDate(
                        values.starts_at ?? initialValues.starts_at,
                        isEditing,
                      )}
                      required
                      valueFormat="MM/DD/YYYY"
                      {...props}
                    />
                  )}
                </Field>
              </div>

              {isEditing && (
                <Field name="state">
                  {({ input, meta }) => (
                    <RadioField
                      id={`${namespace}__state-field`}
                      label="State"
                      className="pb-2"
                      options={STATE_OPTIONS}
                      input={{
                        ...input,
                        onChange: (e, disabled) => {
                          input.onChange(e.target.value, disabled);
                        },
                      }}
                      required
                      {...meta}
                    />
                  )}
                </Field>
              )}
              <Field name="ignore_social_care_expired_dates">
                {({ input, meta }) => (
                  <RadioField
                    className="w-full"
                    postLabelContent={
                      <p className="pb-1 text-xs">
                        Controls if the SCC End Date should be strictly enforced
                        when creating a contracted service note. Start date is
                        always enforced. Rigid SCC End date validation vs
                        warning when adding a contracted service note
                      </p>
                    }
                    label="Social Care Coverage Validation"
                    labelKey="label"
                    id={`${namespace}__ignore-social-care-expired-dates-field`}
                    options={SCC_VALIDATION_OPTIONS}
                    input={{
                      ...input,
                      onChange: (e, disabled) => {
                        input.onChange(e.target.value, disabled);
                      },
                    }}
                    {...meta}
                  />
                )}
              </Field>
              <div
                className="flex justify-end gap-x-4"
                data-testid={`${namespace}-buttons-container`}
              >
                <Button
                  className="w-32"
                  data-testid="cancel-create-fs-btn"
                  label="Cancel"
                  onClick={onCancel}
                />
                <Button
                  className="w-32"
                  label={isEditing ? "Update" : "Create"}
                  primary
                  type="submit"
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

FeeScheduleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  feeScheduleId: PropTypes.string,
};

FeeScheduleForm.defaultProps = {
  initialValues: {},
  payerId: null,
};

export default FeeScheduleForm;
