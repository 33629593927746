import React, { useState, useMemo } from "react";
import { Form, Field } from "react-final-form";
import moment from "moment";
import PropTypes from "prop-types";
import { Button, InputField, RadioField } from "@unite-us/app-components";
import { DraftEditorField } from "@unite-us/client-utils"; //Did not use app-components I was not able to get the initial value showing
import { SelectField, DateField } from "@unite-us/ui"; // Selectfield behavior seems buggy in app-components
import { isEmpty } from "lodash";
import { useNavigate } from "react-router";
import NavigationHeader from "components/NavigationHeader";
import { createServiceOptions } from "utils/dataTransformers";
import { feeScheduleProgramFormConstants } from "./utils/constants";
import {
  isInteger,
  isRequired,
  validateFSPEndDate,
  validateFSPStartDate,
} from "utils/validations";

const getMinDate = (pickedStartDate, feeScheduleStartsAt) => {
  const pickedStartMoment = moment.utc(pickedStartDate, "MM/DD/YYYY");
  if (pickedStartMoment.isAfter(moment.utc(feeScheduleStartsAt))) {
    return pickedStartMoment.add("12", "h").format("MM/DD/YYYY");
  }
  return moment.utc(feeScheduleStartsAt).add("12", "h").format("MM/DD/YYYY");
};

const getMaxDate = (pickedEndDate, feeScheduleEndsAt) => {
  const pickedEndMoment = moment.utc(pickedEndDate, "MM/DD/YYYY");
  if (pickedEndMoment.isBefore(moment.utc(feeScheduleEndsAt))) {
    return pickedEndMoment.subtract("12", "h").format("MM/DD/YYYY");
  }
  return moment(feeScheduleEndsAt).subtract("12", "h").format("MM/DD/YYYY");
};

const FeeScheduleProgramForm = ({
  onSubmit,
  initialValues,
  feeSchedule,
  feeScheduleProgramId,
  services,
}) => {
  const {
    PAYMENT_TYPE_OPTIONS,
    REQUIRES_UNIT_RATE,
    REQUIRES_UNIT_TYPE,
    REQUIRES_CAP_INFO,
    STATE_OPTIONS,
    BOOL_OPTIONS,
    AUTHORIZATION_OPTIONS,
    EMPTY_DRAFT_FIELD,
  } = feeScheduleProgramFormConstants;

  const [paymentType, setPaymentType] = useState(initialValues?.payment_type);
  const [isDisabledUnit, setIsDisabledUnit] = useState(isEmpty(paymentType));
  const requiredOnPaymentType = useMemo(
    () => ({
      isRequiredCapInfo: REQUIRES_CAP_INFO.includes(paymentType),
      isRequiredUnitRate: REQUIRES_UNIT_RATE.includes(paymentType),
      isRequiredUnitType: REQUIRES_UNIT_TYPE.includes(paymentType),
    }),
    [paymentType, REQUIRES_CAP_INFO, REQUIRES_UNIT_RATE, REQUIRES_UNIT_TYPE],
  );
  const SERVICE_TYPE_OPTIONS = createServiceOptions(services);
  const namespace = "fee-schedule-program-form";

  const feeScheduleStartsAt =
    feeSchedule.starts_at ?? moment.utc().startOf("day").toISOString();
  const feeScheduleEndsAt =
    feeSchedule.ends_at ??
    moment.utc().startOf("day").add("7", "days").toISOString();
  const feeScheduleId = feeSchedule.id;
  const navigate = useNavigate();
  const isEditing = !!feeScheduleProgramId;
  const backRedirection = isEditing
    ? `/fee-schedules/${feeScheduleId}/fee-schedule-programs/${feeScheduleProgramId}`
    : `/fee-schedules/${feeScheduleId}`;
  const title = isEditing
    ? "Edit Fee Schedule Program"
    : "Add New Fee Schedule Program";
  const onCancel = () => navigate(backRedirection);

  return (
    <div className="flex flex-col items-center justify-center w-full pb-4">
      <NavigationHeader
        backButtonRedirectTo={backRedirection}
        dataTestId={`${namespace}-navigation-header`}
      />
      <div className="flex flex-row justify-start w-2/5 min-w-80 py-4">
        <h1 className="text-xl font-semibold">{title}</h1>
      </div>
      <div className="border-2 rounded bg-medium-fill-grey p-8 w-2/5 min-w-80">
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Field name="name" validate={(val) => isRequired(val)}>
                {(props) => (
                  <InputField
                    id={`${namespace}__name`}
                    label="Fee Schedule Program Name"
                    omitLabelStyles
                    dataTestId={`${namespace}-name`}
                    className="w-full mb-0.5"
                    required
                    {...props}
                  />
                )}
              </Field>
              <Field name="external_code" className="pb-1">
                {(props) => (
                  <InputField
                    id={`${namespace}__external_code`}
                    label="External Code"
                    omitLabelStyles
                    dataTestId={`${namespace}-external-code`}
                    className="w-full"
                    {...props}
                  />
                )}
              </Field>
              <Field name="services">
                {(props) => (
                  <SelectField
                    id={`${namespace}__services`}
                    label="Service Type"
                    dataTestId={`${namespace}-services`}
                    className="w-full"
                    omitLabelStyles
                    options={SERVICE_TYPE_OPTIONS}
                    multiple
                    labelKey="name"
                    valueKey="id"
                    {...props}
                  />
                )}
              </Field>
              <div className="flex gap-x-6">
                <Field
                  name="unit"
                  validate={(val) =>
                    requiredOnPaymentType.isRequiredUnitType &&
                    isRequired(val, "Unit Type is required")
                  }
                  className="pb-1"
                >
                  {(props) => (
                    <InputField
                      id={`${namespace}__unit`}
                      label="Unit Type"
                      omitLabelStyles
                      className="w-full"
                      dataTestId={`${namespace}-unit`}
                      required={requiredOnPaymentType.isRequiredUnitType}
                      {...props}
                    />
                  )}
                </Field>
                <Field
                  name="unit_rate"
                  validate={(val) =>
                    (requiredOnPaymentType.isRequiredUnitRate &&
                      isRequired(val)) ||
                    isInteger(val)
                  }
                  className="pb-1"
                >
                  {(props) => (
                    <InputField
                      id={`${namespace}__unit_rate`}
                      label="Unit Rate"
                      disabled={isDisabledUnit}
                      className="w-full"
                      omitLabelStyles
                      dataTestId={`${namespace}-unit-rate`}
                      required={requiredOnPaymentType.isRequiredUnitRate}
                      {...props}
                    />
                  )}
                </Field>
              </div>
              <Field
                name="payment_type"
                validate={(val) => isRequired(val, "Payment Type is required")}
              >
                {(props) => (
                  <SelectField
                    id={`${namespace}__payment_type`}
                    label="Payment Type"
                    dataTestId={`${namespace}-payment-type`}
                    className="w-full"
                    required
                    clearable={false}
                    searchEnabled={false}
                    value={values?.payment_type}
                    options={PAYMENT_TYPE_OPTIONS}
                    onChange={(value) => {
                      setPaymentType(value);
                      setIsDisabledUnit(false);
                    }}
                    {...props}
                  />
                )}
              </Field>
              <div className="flex w-full gap-x-6">
                <Field
                  name="starts_at"
                  className="w-1/2"
                  validate={(val) =>
                    validateFSPStartDate(
                      val,
                      values.ends_at,
                      feeScheduleStartsAt,
                    )
                  }
                >
                  {({ input, meta }) => (
                    <DateField
                      className="w-1/2"
                      id={`${namespace}__starts-at`}
                      label="Start Date"
                      dataTestId={`${namespace}-starts-at`}
                      minDate={moment
                        .utc(feeScheduleStartsAt)
                        .format("MM/DD/YYYY")}
                      maxDate={getMaxDate(values.ends_at, feeScheduleEndsAt)}
                      required
                      valueFormat="MM/DD/YYYY"
                      {...meta}
                      onChange={(e) => {
                        input.onChange(e);
                      }}
                      value={initialValues?.starts_at}
                    />
                  )}
                </Field>
                <Field
                  className="w-1/2"
                  name="ends_at"
                  validate={(val) => validateFSPEndDate(val, values.starts_at)}
                >
                  {({ input, meta }) => (
                    <DateField
                      className="w-1/2"
                      id={`${namespace}__ends-at`}
                      label="End Date"
                      dataTestId={`${namespace}-ends-at`}
                      minDate={getMinDate(
                        values.starts_at,
                        feeScheduleStartsAt,
                      )}
                      maxDate={moment
                        .utc(feeScheduleEndsAt)
                        .add("12", "h")
                        .format("MM/DD/YYYY")}
                      required
                      valueFormat="MM/DD/YYYY"
                      {...meta}
                      onChange={(e) => {
                        input.onChange(e);
                      }}
                      value={initialValues?.ends_at}
                    />
                  )}
                </Field>
              </div>
              {isEditing && (
                <Field name="state">
                  {({ input, meta }) => (
                    <RadioField
                      type="radio"
                      className="pb-4"
                      id={`${namespace}__state`}
                      dataTestId={`${namespace}-state`}
                      label="Status"
                      labelClassName="text-dark-grey"
                      options={STATE_OPTIONS}
                      input={{
                        ...input,
                        onChange: (e, disabled) => {
                          input.onChange(e.target.value, disabled);
                        },
                      }}
                      {...meta}
                    />
                  )}
                </Field>
              )}
              <Field name="billable" validate={(val) => isRequired(val)}>
                {({ input, meta }) => (
                  <RadioField
                    className="pb-4"
                    type="radio"
                    id={`${namespace}__billable`}
                    dataTestId={`${namespace}-billable`}
                    label="Billable"
                    labelClassName="text-dark-grey"
                    options={BOOL_OPTIONS}
                    required
                    input={{
                      ...input,
                      onChange: (e, disabled) => {
                        input.onChange(e.target.value, disabled);
                      },
                    }}
                    {...meta}
                  />
                )}
              </Field>
              <Field
                name="authorization_required"
                validate={(val) => isRequired(val)}
              >
                {({ input, meta }) => (
                  <RadioField
                    className="pb-4"
                    type="radio"
                    id={`${namespace}__authorization`}
                    dataTestId={`${namespace}-authorization`}
                    label="Authorization"
                    labelClassName="text-dark-grey"
                    options={AUTHORIZATION_OPTIONS}
                    input={{
                      ...input,
                      onChange: (e, disabled) => {
                        input.onChange(e.target.value, disabled);
                      },
                    }}
                    required
                    {...meta}
                  />
                )}
              </Field>
              {values.authorization_required === "true" && (
                <Field
                  name="auto_authorizes"
                  validate={(val) => isRequired(val)}
                >
                  {({ input, meta }) => (
                    <RadioField
                      className="pb-4"
                      type="radio"
                      id={`${namespace}__auto_authorizes`}
                      dataTestId={`${namespace}-auto-authorizes`}
                      label="Auto-Approved Authorization"
                      postLabelContent={
                        <p className="text-xs">Skip UM approval step</p>
                      }
                      labelClassName="text-dark-grey"
                      options={BOOL_OPTIONS}
                      required
                      input={{
                        ...input,
                        onChange: (e, disabled) => {
                          input.onChange(e.target.value, disabled);
                        },
                      }}
                      {...meta}
                    />
                  )}
                </Field>
              )}
              {values.authorization_required === "true" && (
                <Field
                  name="can_invoice_above_remaining_authorized_amount"
                  validate={(val) => isRequired(val)}
                >
                  {({ input, meta }) => (
                    <RadioField
                      className="pb-4"
                      type="radio"
                      postLabelContent={
                        <div className="text-xs">
                          When it's on, show warnings when Invoice Amount
                          exceeds the Authorized Amount. These warnings do not
                          prevent users from submitting. The warnings are
                          displayed to: <br />
                          <ol className="list-decimal list-inside">
                            <li>
                              CBO Case Manager when they create a contracted
                              service note
                            </li>
                            <li>
                              CBO user, NL and Payer when approving the invoices
                            </li>
                          </ol>
                        </div>
                      }
                      labelClassName="text-dark-grey"
                      id={`${namespace}__invoice_above_auth`}
                      label="Allow Invoicing Over Authorized Amount"
                      options={BOOL_OPTIONS}
                      dataTestId={`${namespace}-invoice-above-auth`}
                      input={{
                        ...input,
                        onChange: (e, disabled) => {
                          input.onChange(e.target.value, disabled);
                        },
                      }}
                      {...meta}
                    />
                  )}
                </Field>
              )}
              <Field
                name="force_zcode_associations"
                validate={(val) => isRequired(val)}
              >
                {({ input, meta }) => (
                  <RadioField
                    className="pb-4"
                    type="radio"
                    id={`${namespace}__force_zcode_associations`}
                    dataTestId={`${namespace}-force-zcodes-radio-group`}
                    label="Force Zcode Associations"
                    required
                    labelClassName="text-dark-grey"
                    options={BOOL_OPTIONS}
                    input={{
                      ...input,
                      onChange: (e, disabled) => {
                        input.onChange(e.target.value, disabled);
                      },
                    }}
                    {...meta}
                  />
                )}
              </Field>
              <Field
                name="force_procedure_code_associations"
                validate={(val) => isRequired(val)}
              >
                {({ input, meta }) => (
                  <RadioField
                    className="pb-4"
                    type="radio"
                    id={`${namespace}__force_procedure_code_associations`}
                    dataTestId={`${namespace}-force-procedure-codes-radio-group`}
                    label="Force Procedure Code Associations"
                    required
                    labelClassName="text-dark-grey"
                    options={BOOL_OPTIONS}
                    input={{
                      ...input,
                      onChange: (e, disabled) => {
                        input.onChange(e.target.value, disabled);
                      },
                    }}
                    {...meta}
                  />
                )}
              </Field>
              <Field
                name="force_procedure_code_modifier_associations"
                validate={(val) => isRequired(val)}
              >
                {({ input, meta }) => (
                  <RadioField
                    className="pb-4"
                    type="radio"
                    id={`${namespace}__force_procedure_code_modifier_associations`}
                    dataTestId={`${namespace}-force-modifiers-radio-group`}
                    label="Force Procedure Code Modifier Associations"
                    required
                    labelClassName="text-dark-grey"
                    options={BOOL_OPTIONS}
                    input={{
                      ...input,
                      onChange: (e, disabled) => {
                        input.onChange(e.target.value, disabled);
                      },
                    }}
                    {...meta}
                  />
                )}
              </Field>
              <Field name="description" className="py-1">
                {(props) => (
                  <DraftEditorField
                    id={`${namespace}__description`}
                    label={
                      <>
                        <label>Description</label>
                        <p className="text-xs normal-case tracking-normal">
                          Write a few sentences about the program.
                        </p>
                      </>
                    }
                    dataTestId={`${namespace}-description`}
                    {...props}
                  />
                )}
              </Field>
              <Field name="eligibility">
                {(props) => (
                  <DraftEditorField
                    id={`${namespace}__eligibility`}
                    label={
                      <>
                        <label>Eligibility</label>
                        <p className="text-xs normal-case tracking-normal">
                          Define any rules on who can be a part of this program.
                        </p>
                      </>
                    }
                    dataTestId={`${namespace}-eligibility`}
                    {...props}
                  />
                )}
              </Field>
              <Field
                name="cap_information"
                validate={(val) =>
                  requiredOnPaymentType.isRequiredCapInfo &&
                  isRequired(val === EMPTY_DRAFT_FIELD ? null : val)
                }
              >
                {(props) => (
                  <DraftEditorField
                    id={`${namespace}__cap_information`}
                    label={
                      <>
                        <label>Cap Information</label>
                        <p className="text-xs normal-case tracking-normal">
                          Outline the spending limit.
                        </p>
                      </>
                    }
                    dataTestId={`${namespace}-cap-information`}
                    {...props}
                  />
                )}
              </Field>
              <Field name="billing_limits">
                {(props) => (
                  <DraftEditorField
                    id={`${namespace}__billing_limits`}
                    label={
                      <>
                        <label>Billing Limits</label>
                        <p className="text-xs normal-case tracking-normal">
                          Determine the maximum amount an organization gets
                          billed.
                        </p>
                      </>
                    }
                    dataTestId={`${namespace}-billing-limits`}
                    {...props}
                  />
                )}
              </Field>
              <div
                className="flex justify-end gap-x-4 pt-8"
                data-testid={`${namespace}-buttons-container`}
              >
                <Button
                  className="w-32"
                  data-testid="cancel-create-fsp-btn"
                  label="Cancel"
                  secondary
                  onClick={onCancel}
                />
                <Button
                  className="w-32"
                  label={isEditing ? "Update" : "Create"}
                  primary
                  type="submit"
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

FeeScheduleProgramForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  feeSchedule: PropTypes.object,
  feeScheduleProgramId: PropTypes.string,
  services: PropTypes.object,
};

FeeScheduleProgramForm.defaultProps = {
  initialValues: {},
  feeSchedule: {},
};

export default FeeScheduleProgramForm;
