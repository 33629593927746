export const createServiceOptions = (services) => {
  let serviceOptions = Object.values(services);
  serviceOptions = serviceOptions.map((service) => ({
    id: service.id,
    name: service.name,
    children: service.children.map((child) => ({
      id: child.id,
      name: child.name,
      parent: service.id,
    })),
  }));
  return serviceOptions;
};
