import React, { useState, useRef, useEffect } from "react";
import { useEmployees } from "api/core/employeeHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import { Button, CheckBoxField } from "@unite-us/app-components";
import { toggleSelectAllRowsSet } from "utils/checkboxUtils";
import { capitalize } from "lodash";
import { useParams } from "react-router";
import AddCareManagersModal from "./components/AddCareManagersModal";

const CareManagers = () => {
  const feeScheduleId = useParams().feeScheduleId;
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [{ sortBy, sortDirection }, setSort] = useState({
    sortBy: "last_name",
    sortDirection: "asc",
  });
  const {
    data: careManagersResponse,
    isFetching,
    isError,
  } = useEmployees({
    filters: { fee_schedule_id: feeScheduleId },
    include: "provider",
    pageSize,
    pageNumber,
    sort: sortDirection === "asc" ? sortBy : `-${sortBy}`,
  });
  const { data: careManagers, paging } = careManagersResponse || {};
  const careManagersLength = careManagers?.length || 0;
  const [selectedCareManagers, setSelectedCareManagers] = useState(new Set());
  const isAllVisibleRowsSelected =
    selectedCareManagers.size === careManagersLength && careManagersLength > 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  // Will be modified in the future by https://uniteus.atlassian.net/browse/TOOL-1216
  useEffect(() => {
    if (isModalOpen) {
      modalRef.current?.openModal();
    } else {
      modalRef.current?.closeModal();
    }
  }, [isModalOpen]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const tableHeaders = [
    {
      label: (
        <CheckBoxField
          id="care-managers-select-all-checkbox"
          label=""
          hideLabel
          showError={false}
          disabled={false}
          checked={isAllVisibleRowsSelected}
          onChange={() => {
            toggleSelectAllRowsSet(
              careManagers,
              setSelectedCareManagers,
              selectedCareManagers.size === careManagersLength,
            );
          }}
        />
      ),
      className: "text-xs",
    },
    {
      label: "Name",
      className: "w-1/4",
      isSortable: true,
      sortBy: sortBy,
      sortDirection: sortDirection,
      colName: "last_name",
      setSort: setSort,
    },
    { label: "Organization", className: "w-1/4" },
    { label: "User Email", className: "w-1/4" },
    { label: "User ID", className: "w-1/4" },
  ];

  const tableBody = careManagers?.map((careManager) => ({
    rowId: careManager.id,
    rowData: [
      {
        data: (
          <CheckBoxField
            id={`select-checkbox-${careManager.id}`}
            label=""
            hideLabel
            showError={false}
            disabled={false}
            checked={selectedCareManagers.has(careManager.id)}
            onChange={() => {
              setSelectedCareManagers((prevState) => {
                const newState = new Set(prevState);
                if (newState.has(careManager.id)) {
                  newState.delete(careManager.id);
                } else {
                  newState.add(careManager.id);
                }
                return newState;
              });
            }}
          />
        ),
        onCellClick: (e) => {
          e.stopPropagation();
        },
        className: "text-xs",
      },
      {
        data: `${capitalize(careManager.last_name)}, ${capitalize(careManager.first_name)}`,
      },
      { data: careManager.provider?.name },
      { data: careManager.email },
      { data: careManager.id },
    ],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-xl content-center">Care Managers</h1>
        <div className="flex flex-row space-x-4">
          <Button
            id="add-users-button"
            icon={{ name: "Add", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Add Users"
            data-testid="add-users-button"
            onClick={openModal}
          />
          <Button
            id="remove-users-button"
            icon={{ name: "MinusCircle", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Remove Users"
            data-testid="remove-users-button"
            disabled
          />
        </div>
      </div>
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        dataTestId="care-managers-table"
        onRowClick={() => {}}
        emptyTableMessage="No Care Managers are added to this Fee Schedule."
        emptyTableClassName="max-h-40"
        showHeaderOnEmptyTable={false}
        errorMessage="Error fetching Care Managers."
        className={tableBody?.length !== 0 ? "!h-fit" : ""}
      />
      {isModalOpen && (
        <AddCareManagersModal
          modalRef={modalRef}
          closeModal={closeModal}
          feeScheduleId={feeScheduleId}
          careManagers={careManagers}
        />
      )}
    </>
  );
};

export default CareManagers;
