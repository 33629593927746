import React from "react";
import EditPlan from "components/pages/Plan/EditPlan";
import { useParams } from "react-router";

const PayerEditPlan = () => {
  const { payerId, planId } = useParams();
  const returnUrl = `/payers/${payerId}/plans/${planId}`;

  return <EditPlan returnUrl={returnUrl} />;
};

export default PayerEditPlan;
