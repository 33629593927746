import { useFind } from "api/apiHooks.js";
import {
  useDefaultQueryConfig,
  useDefaultPagingQueryConfig,
} from "api/apiUtils.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";

export const useForms = (filters, pageSize, pageNumber, sort) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind("form", filters, jsonApiAdapter, {
    queryConfig: { ...defaultPagingQueryConfig },
    page: { number: pageNumber || 1, size: pageSize || 15 },
    sort: sort || "",
  });
};

export const useFindPlanEnrollmentForms = (planId) => {
  const { authToken } = useAuthContext();
  const defaultQueryConfig = useDefaultQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind(
    "form",
    {
      "form_usages.usage_type": "enrollment",
      "form_usages.form_utilizer": planId,
      "form_usages.form_utilizer.type": "plan",
    },
    jsonApiAdapter,
    { queryConfig: defaultQueryConfig },
  );
};
