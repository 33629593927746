import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { useFindPlan } from "api/core/planHooks";
import Spinner from "components/Spinner/Spinner";
import PlanHeader from "./PlanHeader";
import { Outlet } from "react-router";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const PlanView = ({ crumbs, basePath, previousPath }) => {
  const { planId } = useParams();
  const {
    data: plan,
    isFetching: isFetchingPlan,
    isError: isErrorPlan,
  } = useFindPlan(planId);
  const shouldRenderTabComponent = !isFetchingPlan && !isErrorPlan;

  return (
    <>
      <PlanHeader
        plan={plan}
        crumbs={crumbs}
        basePath={basePath}
        previousPath={previousPath}
      />
      <div className="px-20 pt-8">
        {isFetchingPlan && <Spinner center />}
        {isErrorPlan && (
          <ErrorMessage
            navigateText="Go Back To The Previous Page"
            navigateUrl={previousPath}
            errorText="Error Fetching Plan"
            dataTestId="plan-fetch-error-message"
          />
        )}
        {shouldRenderTabComponent && <Outlet context={plan || {}} />}
      </div>
    </>
  );
};

PlanView.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
    }).isRequired,
  ).isRequired,
  basePath: PropTypes.string.isRequired,
  previousPath: PropTypes.string.isRequired,
};

export default PlanView;
