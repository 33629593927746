import React from "react";
import { useFindFeeScheduleScreening } from "api/core/feeScheduleScreeningHooks";
import { useFindFeeSchedule } from "api/core/feeScheduleHooks";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import { Outlet, useParams } from "react-router";
import FeeScheduleScreeningHeader from "./components/FeeScheduleScreeningHeader";

const FeeScheduleScreening = () => {
  const { feeScheduleScreeningId, feeScheduleId } = useParams();

  const {
    data: feeScheduleScreening,
    isFetching: isFetchingFeeScheduleScreening,
    isError: isErrorFeeScheduleScreening,
  } = useFindFeeScheduleScreening(feeScheduleScreeningId);

  const {
    data: feeSchedule,
    isFetching: isFetchingFeeSchedule,
    isError: isErrorFeeSchedule,
  } = useFindFeeSchedule(feeScheduleId);

  const crumbs = [
    { label: "Fee Schedules", href: "/fee-schedules" },
    { label: feeSchedule?.name, href: `/fee-schedules/${feeScheduleId}` },
    { label: feeScheduleScreening?.name },
  ];

  const isFetching = isFetchingFeeSchedule || isFetchingFeeScheduleScreening;
  const isError = isErrorFeeSchedule || isErrorFeeScheduleScreening;
  const shouldRenderTabComponent = !isFetching && !isError;

  return (
    <>
      <div className="h-full flex flex-grow-0 flex-col">
        {!isError && !isFetching && (
          <FeeScheduleScreeningHeader
            feeScheduleScreening={feeScheduleScreening}
            feeScheduleId={feeScheduleId}
            crumbs={crumbs}
          />
        )}
        <div className="pt-8 pb-10 px-20 h-full flex flex-grow-0 flex-col overflow-y-auto">
          {isFetching && <Spinner center />}
          {isError && (
            <ErrorMessage
              dataTestId="fee-schedule-screening-error"
              errorText="Error Fetching Fee Schedule Screening"
              navigateUrl={
                isErrorFeeSchedule
                  ? "/fee-schedules/"
                  : `/fee-schedules/${feeScheduleId}/`
              }
              navigateText="Go Back to Fee Schedule Page"
            />
          )}
          {shouldRenderTabComponent && <Outlet />}
        </div>
      </div>
    </>
  );
};

export default FeeScheduleScreening;
