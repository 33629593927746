import React, { useState, useRef, useEffect } from "react";
import { usePlans } from "api/core/planHooks";
import { usePayers } from "api/core/payerHooks";
import { useFindFeeSchedule } from "api/core/feeScheduleHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import { Button, Modal } from "@unite-us/app-components";
import { capitalize } from "lodash";
import moment from "moment";
import { AddPlanToFeeSchedule } from "./components/AddPlanToFeeSchedule";
import { useParams, useNavigate } from "react-router";

const FeeSchedulePlans = () => {
  const feeScheduleId = useParams().feeScheduleId;
  const navigate = useNavigate();
  const {
    data: feeSchedule,
    isFetching: isFetchingFeeSchedule,
    isError: isErrorFeeSchedule,
  } = useFindFeeSchedule(feeScheduleId);
  const planIds = feeSchedule?.plans?.map((plan) => plan.id).toString();
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  // Will be modified in the future by https://uniteus.atlassian.net/browse/TOOL-1216
  useEffect(() => {
    if (isModalOpen) {
      modalRef.current?.openModal();
    } else {
      modalRef.current?.closeModal();
    }
  }, [isModalOpen]);

  const {
    data: plansResponse,
    isFetching: isFetchingPlans,
    isError: isErrorPlans,
  } = usePlans({
    filters: { id: planIds || "" },
    include: "",
    pageSize,
    pageNumber,
  });
  const { data: plans, paging } = plansResponse || {};
  const payerIds = Array.from(
    new Set(plans?.map((plan) => plan.payer?.id)),
  ).toString();

  const {
    data: payersResponse,
    isFetching: isFetchingPayers,
    isError: isErrorPayers,
  } = usePayers({
    filters: { id: payerIds || "" },
    include: "",
  });
  const { data: payers } = payersResponse || {};

  const tableHeaders = [
    { label: "Plan Name", className: "w-1/4" },
    { label: "Plan Type", className: "w-1/4" },
    { label: "Payer Name", className: "w-1/4" },
    { label: "External ID", className: "w-1/4" },
  ];

  const tableBody = plans?.map((plan) => ({
    rowId: plan.id,
    rowData: [
      { data: plan.name },
      { data: capitalize(plan.plan_type) },
      { data: payers.filter((payer) => payer.id === plan.payer.id)[0]?.name },
      { data: plan.external_id },
    ],
  }));

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-xl content-center">Associated Plans</h1>
        <div className="flex flex-row space-x-4">
          <Button
            id="add-plan-button"
            icon={{ name: "Add", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Add Plan"
            data-testid="add-plan-button"
            onClick={openModal}
            disabled={moment.utc().isAfter(moment(feeSchedule.ends_at).utc())}
          />
        </div>
      </div>
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={
          isFetchingPlans || isFetchingPayers || isFetchingFeeSchedule
        }
        isError={isErrorPlans || isErrorPayers || isErrorFeeSchedule}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        dataTestId="fee-schedule-plans-table"
        onRowClick={(planId) =>
          navigate(`/fee-schedules/${feeScheduleId}/plans/${planId}`)
        }
        emptyTableMessage='Click "Add Plan" to add Plans'
        errorMessage="Error fetching Plans."
        className={tableBody?.length !== 0 ? "!h-fit" : ""}
      />
      {isModalOpen && (
        <Modal
          modalContentStyles="overflow-y-auto grid"
          ref={modalRef}
          title="Add New Social Plan"
          size="jumbo"
          data-testid="add-social-plan-modal"
          spinnerShow={false}
          onRequestClose={closeModal}
          showBorderRadius={true}
          disableScroll={true}
          modal
        >
          <AddPlanToFeeSchedule
            onCancelAddNewPlan={closeModal}
            onSubmitAddNewPlan={closeModal}
            feeSchedule={feeSchedule}
          />
        </Modal>
      )}
    </>
  );
};

export default FeeSchedulePlans;
