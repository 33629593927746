const getAllServiceChildrenNames = (data) => {
  if (!data) return "";

  return Object.values(data)
    .flatMap((category) => category.children)
    .map((child) => child.name)
    .join(", ");
};

export default getAllServiceChildrenNames;
