import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const EmptyTable = ({ className, message, component }) => (
  <div className={classNames("flex bg-white items-center h-full", className)}>
    <div className="flex-1 text-center">
      <h3 className="p-4 text-baby-boy-blue font-normal whitespace-pre-line">
        {message}
      </h3>
      {component}
    </div>
  </div>
);

EmptyTable.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  component: PropTypes.node,
};

EmptyTable.defaultProps = {
  className: "",
  message: "There is no available information to display.",
  component: <></>,
};

export default EmptyTable;
