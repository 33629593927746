import React from "react";
import { useOutletContext } from "react-router";
import PlanOverview from "components/pages/Plan/PlanOverview";

const FeeSchedulePlanOverview = () => {
  const plan = useOutletContext();

  return (
    <div data-testid="fee-schedule-plan-overview-container">
      <PlanOverview plan={plan} />
    </div>
  );
};

export default FeeSchedulePlanOverview;
