import React from "react";
import EditPlan from "components/pages/Plan/EditPlan";
import { useParams } from "react-router";

const FeeScheduleEditPlan = () => {
  const { feeScheduleId, planId } = useParams();
  const returnUrl = `/fee-schedules/${feeScheduleId}/plans/${planId}`;

  return <EditPlan returnUrl={returnUrl} />;
};

export default FeeScheduleEditPlan;
