import React from "react";
import { useAuthorizationRejectionReasons } from "api/core/authorizationRejectionReasonHooks";
import CustomTable from "components/Tables/CustomTable";
import { Button } from "@unite-us/app-components";
import { capitalize } from "lodash";
import { useParams } from "react-router";

const AuthorizationRejectionReasons = () => {
  const feeScheduleId = useParams().feeScheduleId;
  const {
    data: authorizationRejectionReasonsResponse,
    isFetching,
    isError,
  } = useAuthorizationRejectionReasons({
    filters: { fee_schedule: feeScheduleId },
    include: "",
  });
  const { data: rejectionReasons } =
    authorizationRejectionReasonsResponse || {};

  const tableHeaders = [
    { label: "Code", className: "w-1/6 border-r" },
    { label: "Rejection Reason", className: "w-2/5" },
    { label: "State", className: "w-1/12" },
    { label: "Description", className: "w-2/5" },
  ];

  const tableBody = rejectionReasons?.map((rejectionReason) => ({
    rowId: rejectionReason.id,
    rowData: [
      { data: rejectionReason.code, className: "border-r" },
      { data: rejectionReason.display_name },
      { data: capitalize(rejectionReason.state) },
      { data: rejectionReason.description },
    ],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-xl content-center">
          Authorization Rejection Reasons
        </h1>
        <Button
          id="edit-authorization-rejection-reasons-button"
          icon={{ name: "Edit", color: "text-blue", position: "left" }}
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
          }
          label="Edit Reasons"
          data-testid="edit-authorization-rejection-reasons-button"
          disabled
        />
      </div>
      <CustomTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        dataTestId="authorization-rejection-reasons-table"
        onRowClick={() => {}}
        emptyTableMessage={`No Authorization Rejection Reasons added.\nAdd all standard rejection codes or manually add reasons.`}
        emptyTableComponent={
          <div className="flex flex-row space-x-4 justify-center">
            <Button
              id="add-standard-rejection-reasons-button"
              className={
                "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
              }
              label="Add All Standard Rejection Reasons"
              data-testid="add-standard-rejection-reasons-button"
              disabled
            />
            <Button
              id="add-manual-rejection-reason-button"
              className={
                "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
              }
              label="Add Rejection Reason"
              data-testid="add-manual-rejection-reason-button"
              disabled
            />
          </div>
        }
        emptyTableClassName="max-h-40"
        showHeaderOnEmptyTable={false}
        errorMessage="Error fetching Authorization Rejection Reasons."
        className={tableBody?.length !== 0 ? "!h-fit" : ""}
      />
    </>
  );
};

export default AuthorizationRejectionReasons;
