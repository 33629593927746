import React, { useEffect } from "react";
import { AuthLogout } from "./AuthLogout.js";

export const OAuthLogin = ({ authUrl }) => {
  useEffect(() => {
    const { location } = window;
    const homeUrl = `${location.protocol}//${location.host}/`;
    window.location = homeUrl;
  }, []);

  return (
    <div data-testid="oauth-login" className="oauth-login">
      {<AuthLogout authUrl={authUrl} />}
    </div>
  );
};
