import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { DefaultLayout } from "layouts/DefautLayout.js";
import { OAuthLogin } from "components/auth/OAuthLogin.js";
import { RevokeConsent } from "pages/revoke-consent/RevokeConsent.jsx";
import { Payers } from "pages/payers/Payers.jsx";
import Users from "pages/users/Users.jsx";
import CreatePayer from "pages/payers/CreatePayer.jsx";
import Payer from "pages/payers/_id/Payer.jsx";
import EditPayer from "pages/payers/_id/EditPayer.jsx";
import CreatePlan from "pages/payers/_id/plans/CreatePlan.jsx";
import PayerPlan from "./payers/_id/plans/_id/PayerPlan.jsx";
import PayerPlanOverview from "./payers/_id/plans/_id/Overview/PayerPlanOverview.jsx";
import PayerPlanEnrollmentsAttachments from "./payers/_id/plans/_id/Attachments/PayerPlanEnrollmentsAttachments.jsx";
import PayerEditPlan from "./payers/_id/plans/_id/Edit/PayerEditPlan.jsx";
import { ImportCenter } from "pages/import-center/ImportCenter.jsx";
import { UserAccount } from "pages/users/_id/UserAccount.jsx";
import Forms from "pages/forms/Forms.jsx";
import FeeSchedules from "pages/fee-schedules/FeeSchedules.jsx";
import CreateFeeSchedule from "./fee-schedules/CreateFeeSchedule.jsx";
import FeeSchedule from "pages/fee-schedules/_id/FeeSchedule.jsx";
import EditFeeSchedule from "./fee-schedules/_id/EditFeeSchedule.jsx";
import CreateFeeScheduleProgram from "./fee-schedules/_id/FeeSchedulePrograms/CreateFeeScheduleProgram.jsx";
import EditFeeScheduleProgram from "./fee-schedules/_id/FeeSchedulePrograms/_id/EditFeeScheduleProgram.jsx";
import FeeScheduleProgramsTable from "./fee-schedules/_id/FeeSchedulePrograms/FeeScheduleProgramsTable.jsx";
import FeeScheduleProgram from "./fee-schedules/_id/FeeSchedulePrograms/_id/FeeScheduleProgram.jsx";
import FeeScheduleProgramOverview from "./fee-schedules/_id/FeeSchedulePrograms/_id/FeeScheduleProgramOverview.jsx";
import FeeSchedulePlans from "./fee-schedules/_id/Plans/FeeSchedulePlans.jsx";
import InvoiceRejectionReasons from "./fee-schedules/_id/InvoiceRejectionReasons/InvoiceRejectionReasons.jsx";
import AuthorizationRejectionReasons from "./fee-schedules/_id/AuthorizationRejectionReasons/AuthorizationRejectionReasons.jsx";
import CareManagers from "./fee-schedules/_id/CareManagers/CareManagers.jsx";
import FeeSchedulePlan from "./fee-schedules/_id/Plans/_id/FeeSchedulePlan.jsx";
import FeeSchedulePlanOverview from "./fee-schedules/_id/Plans/_id/Overview/FeeSchedulePlanOverview.jsx";
import FeeSchedulePlanEnrollmentsAttachments from "./fee-schedules/_id/Plans/_id/Attachments/FeeSchedulePlanEnrollmentsAttachments.jsx";
import FeeScheduleEditPlan from "./fee-schedules/_id/Plans/_id/Edit/FeeScheduleEditPlan.jsx";
import FeeScheduleScreenings from "./fee-schedules/_id/FeeScheduleScreenings/FeeScheduleScreenings.jsx";
import FeeScheduleScreening from "./fee-schedules/_id/FeeScheduleScreenings/_id/FeeScheduleScreening.jsx";
import FeeScheduleScreeningOverview from "./fee-schedules/_id/FeeScheduleScreenings/_id/FeeScheduleScreeningOverview.jsx";

// use this for the future home/landing page
// import { Home } from "pages/home/Home.js";

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<DefaultLayout />}>
      <Route index element={<Navigate to="/users" replace={true} />} />
      <Route path="callback" element={<OAuthLogin />} />
      <Route path="revoke-consent" element={<RevokeConsent />} />
      <Route path="users" element={<Users />} />
      <Route path="users/:employeeId" element={<UserAccount />} />
      <Route path="payers" element={<Payers />} />
      <Route path="payers/new" element={<CreatePayer />} />
      <Route path="payers/:payerId/edit" element={<EditPayer />} />
      <Route path="payers/:payerId" element={<Payer />} />
      <Route path="payers/:payerId/plans/new" element={<CreatePlan />} />
      <Route path="payers/:payerId/plans/:planId" element={<PayerPlan />}>
        <Route index element={<Navigate to="overview" />} />
        <Route path="overview" element={<PayerPlanOverview />} />
        <Route
          path="attachments"
          element={<PayerPlanEnrollmentsAttachments />}
        />
      </Route>
      <Route
        path="payers/:payerId/plans/:planId/edit"
        element={<PayerEditPlan />}
      />
      <Route path="import-center" element={<ImportCenter />} />
      <Route path="forms" element={<Forms />} />
      <Route path="fee-schedules" element={<FeeSchedules />} />
      <Route path="fee-schedules/new" element={<CreateFeeSchedule />} />
      <Route path="fee-schedules/:feeScheduleId" element={<FeeSchedule />}>
        <Route index element={<Navigate to="fee-schedule-programs" />} />
        <Route
          path="fee-schedule-programs"
          element={<FeeScheduleProgramsTable />}
        />
        <Route path="plans" element={<FeeSchedulePlans />} />
        <Route
          path="fee-schedule-screenings"
          element={<FeeScheduleScreenings />}
        />
        <Route
          path="invoice-rejection-reasons"
          element={<InvoiceRejectionReasons />}
        />
        <Route
          path="authorization-rejection-reasons"
          element={<AuthorizationRejectionReasons />}
        />
        <Route path="care-managers" element={<CareManagers />} />
      </Route>
      <Route
        path="fee-schedules/:feeScheduleId/fee-schedule-programs/:feeScheduleProgramId"
        element={<FeeScheduleProgram />}
      >
        <Route index element={<Navigate to="overview" />} />
        <Route path="overview" element={<FeeScheduleProgramOverview />} />
      </Route>
      <Route
        path="fee-schedules/:feeScheduleId/fee-schedule-screenings/:feeScheduleScreeningId"
        element={<FeeScheduleScreening />}
      >
        <Route index element={<Navigate to="overview" />} />
        <Route path="overview" element={<FeeScheduleScreeningOverview />} />
      </Route>

      <Route
        path="fee-schedules/:feeScheduleId/edit"
        element={<EditFeeSchedule />}
      />
      <Route
        path="fee-schedules/:feeScheduleId/plans/:planId"
        element={<FeeSchedulePlan />}
      >
        <Route index element={<Navigate to="overview" />} />
        <Route path="overview" element={<FeeSchedulePlanOverview />} />
        <Route
          path="attachments"
          element={<FeeSchedulePlanEnrollmentsAttachments />}
        />
      </Route>
      <Route
        path="fee-schedules/:feeScheduleId/plans/:planId/edit"
        element={<FeeScheduleEditPlan />}
      />
      <Route
        path="fee-schedules/:feeScheduleId/fee-schedule-programs/new"
        element={<CreateFeeScheduleProgram />}
      />
      <Route
        path="fee-schedules/:feeScheduleId/fee-schedule-programs/:feeScheduleProgramId/edit"
        element={<EditFeeScheduleProgram />}
      />
    </Route>
  </Routes>
);
