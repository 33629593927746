import React, { useState } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { useFeeSchedulePrograms } from "api/core/feeScheduleProgramHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import { Button, FilterSingle, Badge } from "@unite-us/app-components";
import SearchBar from "components/SearchBar/SearchBar";
import { capitalize } from "lodash";
import { stateBgColor, textColorForStyleProp } from "utils/stateBgColor";
import { useParams } from "react-router";
import moment from "moment";
import { useNavigate } from "react-router";

const FeeScheduleProgramsTable = () => {
  const navigate = useNavigate();
  const feeScheduleId = useParams().feeScheduleId;
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [filters, setFilters] = useLocalStorage("feeSchedulePrograms", {
    state: "active",
  });
  const [{ sortBy, sortDirection }, setSort] = useState({
    sortBy: "ends_at",
    sortDirection: "asc",
  });
  const {
    data: feeScheduleProgramsResponse,
    isFetching,
    isError,
  } = useFeeSchedulePrograms({
    filters: { ...filters, fee_schedule: feeScheduleId },
    include: "",
    pageSize,
    pageNumber,
    sort: sortDirection === "asc" ? sortBy : `-${sortBy}`,
  });
  const { data: feeSchedulePrograms, paging } =
    feeScheduleProgramsResponse || {};

  const tableHeaders = [
    { label: "Name", className: "w-1/4" },
    { label: "Status", className: "w-1/4" },
    { label: "Start Date", className: "w-1/4" },
    {
      label: "End Date",
      className: "w-1/4",
      isSortable: true,
      sortBy: sortBy,
      sortDirection: sortDirection,
      colName: "ends_at",
      setSort: setSort,
    },
  ];

  const tableBody = feeSchedulePrograms?.map((feeScheduleProgram) => {
    const state = moment
      .utc(new Date())
      .startOf("day")
      .isAfter(moment.utc(feeScheduleProgram.ends_at))
      ? "expired"
      : feeScheduleProgram.state;

    return {
      rowId: feeScheduleProgram.id,
      rowData: [
        { data: capitalize(feeScheduleProgram.name) },
        {
          data: (
            <Badge
              className={`!leading-4 !text-sm px-4 pt ${stateBgColor(state)}`}
              text={capitalize(state)}
              color=""
              style={textColorForStyleProp(state)}
            />
          ),
        },
        { data: moment.utc(feeScheduleProgram.starts_at).format("LL") },
        {
          data: `${moment.utc(feeScheduleProgram.ends_at).format("LL")}${state === "expired" ? " (Expired)" : ""}`,
          className: state === "expired" ? "!text-red" : "",
        },
      ],
    };
  });

  const filtersClassName = "w-1/8 pr-2 pt-2 !max-h-8";

  const handleStatusChange = (target) => {
    setFilters({
      ...filters,
      state: target.value,
    });
  };

  const handleSearchChange = (target) => {
    setFilters({ ...filters, name: target.search });
  };

  const viewFeeScheduleProgram = (programId) =>
    navigate(
      `/fee-schedules/${feeScheduleId}/fee-schedule-programs/${programId}`,
    );

  return (
    <>
      <div className="flex justify-between item-center">
        <h1 className="text-xl content-center">Program Details</h1>
        <Button
          id="add-program-button"
          icon={{ name: "Add", color: "text-blue", position: "left" }}
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
          }
          label="Add Program"
          data-testid="add-program-button"
          onClick={() =>
            navigate(
              `/fee-schedules/${feeScheduleId}/fee-schedule-programs/new`,
            )
          }
        />
      </div>
      <div className="flex flex-row align-middle space-x-6 pb-2">
        <div className={"max-w-xs w-full pt-2"}>
          <SearchBar
            onSubmit={handleSearchChange}
            dataTestId="fee-schedule-programs-search-bar"
            placeholder="Search by Name"
            delay={2000}
            initialValue={filters.name}
            inputClassName=""
            inputStyle={{ height: "40px" }}
            showIcon={false}
          />
        </div>
        <FilterSingle
          key="fee-schedule-programs-status-filter-key"
          uniqIdPrefx="fee-schedule-programs-status-filter"
          name="fee-schedule-programs-status"
          className={filtersClassName}
          dataTestId="fee-schedule-programs-status-filter"
          value={filters.state || "active"}
          options={[
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
            { label: "Archived", value: "archived" },
          ]}
          onFilterChange={handleStatusChange}
          filterKey="feeScheduleProgramStatusFilter"
        />
      </div>
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        dataTestId="fee-schedule-programs-table"
        onRowClick={viewFeeScheduleProgram}
        emptyTableMessage="No programs found"
        errorMessage="Error fetching programs"
      />
    </>
  );
};

export default FeeScheduleProgramsTable;
