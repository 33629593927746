import React, { useRef, useEffect, useState } from "react";
import {
  useInvoiceRejectionReasons,
  useCreateInvoiceRejectionReason,
  useUpdateInvoiceRejectionReason,
} from "api/core/invoiceRejectionReasonHooks";
import { useInvalidateQueries } from "api/apiHooks";
import CustomTable from "components/Tables/CustomTable";
import { Button } from "@unite-us/app-components";
import { useParams } from "react-router";
import { useToast } from "@unite-us/app-components";
import EditRejectionReasons from "components/RejectionReasons/EditRejectionReasons";

const InvoiceRejectionReasons = () => {
  const feeScheduleId = useParams().feeScheduleId;
  const editRejectionReasonsModal = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addToast } = useToast();
  const invalidateQueries = useInvalidateQueries();

  // Will be modified in the future by https://uniteus.atlassian.net/browse/TOOL-1216
  useEffect(() => {
    if (isModalOpen) {
      editRejectionReasonsModal.current?.openModal();
    } else {
      editRejectionReasonsModal.current?.closeModal();
    }
  }, [isModalOpen]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const {
    data: invoiceRejectionReasonsResponse,
    isFetching,
    isError,
  } = useInvoiceRejectionReasons({
    filters: { fee_schedule: feeScheduleId },
    include: "",
  });
  const { data: rejectionReasons } = invoiceRejectionReasonsResponse || {};
  const { createRecord: createInvoiceRejectionReason } =
    useCreateInvoiceRejectionReason({ onError: () => {} });
  const { updateRecord: updateInvoiceRejectionReason } =
    useUpdateInvoiceRejectionReason({ onError: () => {} });

  const tableHeaders = [
    { label: "Code", className: "w-1/6 border-r" },
    { label: "Rejection Reason", className: "w-2/5" },
    { label: "Description", className: "w-2/5" },
  ];

  const tableBody = rejectionReasons?.map((rejectionReason) => ({
    rowId: rejectionReason.id,
    rowData: [
      { data: rejectionReason.code, className: "border-r" },
      { data: rejectionReason.display_name },
      { data: rejectionReason.description },
    ],
  }));

  const confirmationHandler = async (updatedRejectionReasons) => {
    if (!updatedRejectionReasons) return;

    const incompleteRejectionReasons = updatedRejectionReasons.filter(
      (reason) => !reason.display_name || !reason.code,
    );

    if (incompleteRejectionReasons.length > 0) {
      addToast({
        message: "Please fill out all required fields.",
        type: "error",
        dataTestId: "incomplete-rejection-reasons-toast",
      });
      return;
    }

    const editedReasons = updatedRejectionReasons.filter(
      (reason) => reason.isEdited,
    );

    const existingReasons = editedReasons.filter((reason) => reason.id);
    const newReasons = editedReasons
      .filter((reason) => !reason.id)
      .map((reason) => ({
        ...reason,
        fee_schedule: feeScheduleId,
      }));

    let hasErrors = false;

    const handleError = (reason, action, error) => {
      addToast({
        message: `Error ${action} invoice rejection reason "${reason.display_name || "unknown"}": ${error.message || "Unknown error"}`,
        type: "error",
        dataTestId: `error-${action}-invoice-rejection-reason-toast`,
      });
      hasErrors = true;
    };

    const preparePayload = (reason) => {
      const { isEdited, ...cleanedReason } = reason;
      return cleanedReason;
    };

    const createNewReasons = async () => {
      await Promise.all(
        newReasons.map(async (reason) => {
          const payload = preparePayload(reason);
          try {
            await createInvoiceRejectionReason(payload);
            delete reason.isEdited;
          } catch (error) {
            handleError(reason, "creating", error);
          }
        }),
      );
    };

    const updateExistingReasons = async () => {
      await Promise.all(
        existingReasons.map(async (reason) => {
          const payload = preparePayload(reason);
          try {
            await updateInvoiceRejectionReason(reason.id, payload);
            delete reason.isEdited;
          } catch (error) {
            handleError(reason, "updating", error);
          }
        }),
      );
    };

    await Promise.all([createNewReasons(), updateExistingReasons()]);

    if (!hasErrors) {
      invalidateQueries("invoice_rejection_reason");
      addToast({
        message: "Invoice Rejection Reasons successfully updated.",
        type: "success",
        dataTestId: "update-invoice-rejection-reasons-success-toast",
      });
      closeModal();
    }
  };

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-xl content-center">Invoice Rejection Reasons</h1>
        <Button
          id="edit-invoice-rejection-reasons-button"
          icon={{ name: "Edit", color: "text-blue", position: "left" }}
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
          }
          label="Edit Reasons"
          data-testid="edit-invoice-rejection-reasons-button"
          onClick={openModal}
        />
      </div>
      <CustomTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        dataTestId="invoice-rejection-reasons-table"
        onRowClick={() => {}}
        emptyTableMessage={`No rejection reasons have been configured for this fee schedule.\nClick "Edit Reasons" to add rejection reasons.`}
        emptyTableClassName="max-h-40"
        showHeaderOnEmptyTable={false}
        errorMessage="Error fetching Invoice Rejection Reasons."
        className={tableBody?.length !== 0 ? "!h-fit" : ""}
      />
      {isModalOpen && (
        <EditRejectionReasons
          reasons={rejectionReasons}
          modalRef={editRejectionReasonsModal}
          confirmationHandler={confirmationHandler}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default InvoiceRejectionReasons;
