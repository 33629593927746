import React from "react";
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  DraftEditorField,
} from "@unite-us/app-components";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import parse from "html-react-parser";
import getAllServiceChildrenNames from "../utils/getAllServiceChildrenNames";
import { useFindFeeScheduleProgram } from "api/core/feeScheduleProgramHooks";
import { useUniteUsServices } from "api/core/serviceHooks";
import { useParams } from "react-router";

const FeeScheduleProgramOverview = () => {
  const { feeScheduleId, feeScheduleProgramId } = useParams();

  const {
    data: feeScheduleProgram,
    isFetching: isFetchingFeeScheduleProgram,
    isError: isErrorFeeScheduleProgram,
  } = useFindFeeScheduleProgram(feeScheduleProgramId);

  const fspServices = feeScheduleProgram?.services || [];
  const serviceIds = fspServices.map((n) => n.id).toString();
  const {
    data: services,
    isFetching: isFetchingServices,
    isError: isErrorServices,
  } = useUniteUsServices({
    filters: { id: serviceIds },
  });
  const service_names = getAllServiceChildrenNames(services);
  const isFetching = isFetchingFeeScheduleProgram || isFetchingServices;
  const isError = isErrorFeeScheduleProgram || isErrorServices;
  return (
    <>
      {isFetching && <Spinner center />}
      {isError && (
        <ErrorMessage
          dataTestId="fee-schedule-program-overview-error"
          errorText="Error Fetching Fee Schedule Program"
          navigateUrl={`/fee-schedules/${feeScheduleId}/fee-schedule-programs`}
          navigateText="Go Back to Fee Schedule Programs Page"
        />
      )}
      {!isError && !isFetching && (
        <div data-testid="fee-schedule-program-overview-base-card">
          <BaseCard>
            <BaseCardHeader
              title="Program Details"
              className="font-bold text-xl bg-gray-100 px-4"
            />
            <BaseCardBody className="p-8 bg-white">
              <div className="grid grid-cols-6">
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  External Code:
                </div>
                <div className="capitalize" data-testid="fs-prog-external-code">
                  {feeScheduleProgram?.external_code || ""}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Service Type:
                </div>
                <div
                  className="capitalize col-span-5 px-0"
                  data-testid="fs-prog-service-type"
                >
                  {feeScheduleProgram?.services &&
                  feeScheduleProgram?.services?.length > 0
                    ? service_names
                    : "-"}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Unit Type:
                </div>
                <div className="capitalize" data-testid="fs-prog-unit-type">
                  {feeScheduleProgram?.unit || ""}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Unit Rate (cents):
                </div>
                <div className="capitalize" data-testid="fs-prog-unit-rate">
                  {feeScheduleProgram?.unit_rate || ""}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Payment Type:
                </div>
                <div className="capitalize" data-testid="fs-prog-payment-type">
                  {feeScheduleProgram?.payment_type || ""}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Cap Information:
                </div>
                <div
                  className="capitalize"
                  data-testid="fs-prog-cap-information"
                >
                  {feeScheduleProgram?.cap_information
                    ? parse(feeScheduleProgram?.cap_information)
                    : ""}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">Billable:</div>
                <div data-testid="fs-prog-billable-requirement">
                  {feeScheduleProgram?.billable ? "Billable" : "Not Billable"}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Authorization:
                </div>
                <div data-testid="fs-prog-auth-requirement">
                  {feeScheduleProgram?.authorization_required
                    ? "Required"
                    : "Not Required"}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Auto-Approved Authorization:
                </div>
                <div data-testid="fs-prog-auto-approved-auth-requirement">
                  {feeScheduleProgram?.auto_authorizes ? "Yes" : "No"}
                </div>
                <div className="col-start-1 pl-0 pb-2 font-bold">
                  Invoicing over authorized amount:
                </div>
                <div data-testid="fs-prog-invoice-above-auth-requirement">
                  {feeScheduleProgram?.can_invoice_above_remaining_authorized_amount
                    ? "Yes"
                    : "No"}
                </div>
              </div>
            </BaseCardBody>
          </BaseCard>

          <BaseCard className="mt-6">
            <BaseCardHeader
              title="Description"
              className="font-bold text-xl bg-gray-100 px-4"
            />
            <BaseCardBody className="p-8 bg-white" withPadding>
              <DraftEditorField
                className="capitalize"
                id="fs_prog_description"
                dataTestId="fs-prog-description"
                readOnly
                label=""
                value={feeScheduleProgram?.description || "N/A"}
              />
            </BaseCardBody>
          </BaseCard>

          <BaseCard className="mt-6">
            <BaseCardHeader
              title="Eligibility"
              className="font-bold text-xl bg-gray-100 px-4"
            />
            <BaseCardBody className="p-8 bg-white" withPadding>
              <DraftEditorField
                className="capitalize"
                id="fs_prog_eligibility"
                dataTestId="fs-prog-eligibility"
                readOnly
                label=""
                value={feeScheduleProgram?.eligibility || "N/A"}
              />
            </BaseCardBody>
          </BaseCard>

          <BaseCard className="mt-6">
            <BaseCardHeader
              title="Cap Information"
              className="font-bold text-xl bg-gray-100 px-4"
            />
            <BaseCardBody className="p-8 bg-white" withPadding>
              <DraftEditorField
                className="capitalize"
                id="fs_prog_cap_information"
                dataTestId="fs-prog-cap-information"
                readOnly
                label=""
                value={feeScheduleProgram?.cap_information || "N/A"}
              />
            </BaseCardBody>
          </BaseCard>

          <BaseCard className="mt-6">
            <BaseCardHeader
              title="Billing Limits"
              className="font-bold text-xl bg-gray-100 px-4"
            />
            <BaseCardBody className="p-8 bg-white" withPadding>
              <DraftEditorField
                className="capitalize"
                dataTestId="fs_prog_billing_limits"
                id="fs-prog-billing-limits"
                readOnly
                label=""
                value={feeScheduleProgram?.billing_limits || "N/A"}
              />
            </BaseCardBody>
          </BaseCard>
        </div>
      )}
    </>
  );
};

export default FeeScheduleProgramOverview;
