import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { getJsonApiAdapter } from "api/apiService.js";
import {
  useDefaultQueryConfig,
  useDefaultMutationConfig,
  useDefaultPagingQueryConfig,
} from "api/apiUtils.js";
import {
  useFindRecord,
  useFind,
  useInvalidateQueries,
  useUpdateRecord,
  useCreateRecord,
} from "api/apiHooks.js";
import { useToast } from "@unite-us/app-components";

export const useFeeSchedulePrograms = ({
  filters = {},
  include,
  pageSize,
  pageNumber,
  sort,
}) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind("fee_schedule_program", { ...filters }, jsonApiAdapter, {
    queryConfig: { ...defaultPagingQueryConfig },
    page: { number: pageNumber || 1, size: pageSize || 15 },
    sort: sort || "",
    include: include || "",
  });
};

export const useFindFeeScheduleProgram = (feeScheduleProgramId) => {
  const { authToken } = useAuthContext();
  const defaultQueryConfig = useDefaultQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFindRecord(
    "fee_schedule_program",
    feeScheduleProgramId,
    jsonApiAdapter,
    { queryConfig: defaultQueryConfig },
  );
};

export const useCreateFeeScheduleProgram = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useCreateRecord("fee_schedule_program", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      onSuccess: () => {
        invalidateQueries("fee_schedule_program");
        invalidateQueries("fee_schedule");
        addToast({
          message: "Fee schedule program created successfully",
          type: "success",
          dataTestId: "create-fee-schedule-program-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error creating fee schedule program: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "create-fee-schedule-program-error-toast",
        });
      },
      ...mutationConfig,
    },
  });
};

export const useUpdateFeeScheduleProgram = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useUpdateRecord("fee_schedule_program", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      onSuccess: () => {
        invalidateQueries("fee_schedule_program");
        invalidateQueries("fee_schedule");
        addToast({
          message: "Fee schedule program updated successfully",
          type: "success",
          dataTestId: "update-fee-schedule-program-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error updating fee schedule program: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "update-fee-schedule-program-error-toast",
        });
      },
      ...mutationConfig,
    },
  });
};
