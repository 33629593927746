import React, { useState } from "react";
import PropTypes from "prop-types";
import { useEmployees } from "api/core/employeeHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import SearchBar from "components/SearchBar/SearchBar";
import { capitalize } from "lodash";
import { CheckBoxField } from "@unite-us/app-components";
import { toggleSelectAllRowsSet } from "utils/checkboxUtils";

const UsersFromOrgTable = ({
  org,
  selectedUsers,
  setSelectedUsers,
  careManagersIds,
}) => {
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [{ sortBy, sortDirection }, setSort] = useState({
    sortBy: "last_name",
    sortDirection: "asc",
  });
  const [filters, setFilters] = useState({
    query: "",
    provider: org.id,
    "roles.key": "payments",
  });
  const {
    data: userResponse,
    isFetching,
    isError,
  } = useEmployees({
    filters: filters,
    include: "",
    pageSize,
    pageNumber,
    sort: sortDirection === "asc" ? sortBy : `-${sortBy}`,
  });
  const { data: users, paging } = userResponse || {};

  const careManagersSet = new Set(careManagersIds);
  const filteredUsers = users?.filter((user) => !careManagersSet.has(user.id));

  const isAllVisibleRowsSelected =
    selectedUsers?.size === filteredUsers?.length && filteredUsers?.length > 0;

  const tableHeaders = [
    {
      label: (
        <CheckBoxField
          id="users-from-org-select-all-checkbox"
          label=""
          hideLabel
          showError={false}
          disabled={false}
          checked={isAllVisibleRowsSelected}
          onChange={() => {
            toggleSelectAllRowsSet(
              filteredUsers,
              setSelectedUsers,
              selectedUsers?.size === filteredUsers.length,
            );
          }}
        />
      ),
      className: "text-xs",
    },
    {
      label: "Name",
      classNames: "w-1/3",
      isSortable: true,
      sortBy: sortBy,
      sortDirection: sortDirection,
      colName: "last_name",
      setSort: setSort,
    },
    { label: "User Email", classNames: "w-1/3" },
    { label: "User ID", classNames: "w-1/3" },
  ];

  const tableBody = users?.map((user) => {
    const isCareManager = careManagersSet.has(user.id);
    return {
      rowId: user.id,
      rowData: [
        {
          data: (
            <CheckBoxField
              id={`select-user-from-org-checkbox-${user.id}`}
              label=""
              hideLabel
              showError={false}
              disabled={isCareManager}
              checked={isCareManager || selectedUsers.has(user.id)}
              onChange={() => {
                setSelectedUsers((prevState) => {
                  const newState = new Set(prevState);
                  if (newState.has(user.id)) {
                    newState.delete(user.id);
                  } else {
                    newState.add(user.id);
                  }
                  return newState;
                });
              }}
            />
          ),
          onCellClick: (e) => {
            e.stopPropagation();
          },
          className: "text-xs",
        },
        {
          data: `${capitalize(user.last_name)}, ${capitalize(user.first_name)}`,
        },
        { data: user.email },
        { data: user.id },
      ],
    };
  });

  const handleOnSubmitSearchBar = (data) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      query: data?.search,
    }));
    setPageNumber(1);
  };

  return (
    <>
      <div className="justify-between flex items-center pb-2">
        <span>Select user(s) under {org.name}</span>
        <span>{selectedUsers.size} selected</span>
      </div>
      <SearchBar
        onSubmit={handleOnSubmitSearchBar}
        dataTestId="users-from-org-search-bar"
        placeholder="Enter user's name or email..."
        delay={2000}
        initialValue={filters?.name}
        inputClassName=""
      />
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        dataTestId="users-from-org-table"
        onRowClick={() => {}}
        emptyTableMessage="No Users found for the Organization."
        emptyTableClassName="min-h-52"
        errorMessage="Error fetching Users."
        className={
          "flex flex-col min-w-full max-h-[55vh] h-[55vh] sm-h:h-[150px] md-h:h-auto"
        }
      />
    </>
  );
};

UsersFromOrgTable.propTypes = {
  org: PropTypes.object.isRequired,
  selectedUsers: PropTypes.instanceOf(Set).isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  careManagersIds: PropTypes.array.isRequired,
};

export default UsersFromOrgTable;
